import PropTypes from "prop-types"
import React, { useState, useCallback, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap"

import { Link } from "react-router-dom"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { ApiAddDictionary } from "../../../api/dictionary"

const CreateDictionary = props => {
  //meta title
  document.title = "Tambah Kamus | Economates"

  const [valueNameId, setValueNameId] = useState("")
  const [valueNameEn, setValueNameEn] = useState("")
  const [valueSubNameId, setValueSubNameId] = useState("")
  const [valueSubNameEn, setValueSubNameEn] = useState("")
  const [definition, setDefinition] = useState([
    {
      descriptionId: "",
      descriptionEn: "",
    },
  ])
  const [exmaple, setExample] = useState([
    {
      descriptionId: "",
      descriptionEn: "",
    },
  ])
  const [disabledButton, setDisabledButton] = useState(true)

  // access type
  const [valueAccessType, setValueAccessType] = useState("0")

  useEffect(() => {
    const filterDefinitionId = definition.filter(d => d.descriptionId === "")
    const filterDefinitionEn = definition.filter(d => d.descriptionEn === "")
    if (
      valueNameId === "" ||
      valueNameEn === "" ||
      filterDefinitionId.length > 0 ||
      filterDefinitionEn.length > 0
    ) {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
  }, [valueNameId, valueNameEn, definition])

  const handleChangeNameId = useCallback(e => {
    const { value } = e.target
    setValueNameId(value)
  }, [])

  const handleChangeNameEn = useCallback(e => {
    const { value } = e.target
    setValueNameEn(value)
  }, [])

  const handleChangeSubNameId = useCallback(e => {
    const { value } = e.target
    setValueSubNameId(value)
  }, [])

  const handleChangeSubNameEn = useCallback(e => {
    const { value } = e.target
    setValueSubNameEn(value)
  }, [])

  const onChangeAccessType = useCallback(e => {
    let { value } = e.target
    setValueAccessType(value)
  }, [])

  const handleAddColumnDefinisi = useCallback(() => {
    const payload = {
      descriptionId: "",
      descriptionEn: "",
    }

    setDefinition(oldState => [...oldState, payload])
  }, [])

  const handleAddColumnExample = useCallback(() => {
    const payload = {
      descriptionId: "",
      descriptionEn: "",
    }

    setExample(oldState => [...oldState, payload])
  }, [])

  const handleRemoveColumnDefinisi = useCallback(
    index => {
      const state = [...definition]
      state.splice(index, 1)

      setDefinition(state)
    },
    [definition]
  )

  const handleRemoveColumnExample = useCallback(
    index => {
      const state = [...exmaple]
      state.splice(index, 1)

      setExample(state)
    },
    [exmaple]
  )

  const handleChangeBenefitId = useCallback(
    (e, index) => {
      const { value } = e.target
      const state = [...definition]
      state[index].descriptionId = value

      setDefinition(state)
    },
    [definition]
  )

  const handleChangeBenefitEn = useCallback(
    (e, index) => {
      const { value } = e.target
      const state = [...definition]
      state[index].descriptionEn = value

      setDefinition(state)
    },
    [definition]
  )

  const handleChangeExampleId = useCallback(
    (e, index) => {
      const { value } = e.target
      const state = [...exmaple]
      state[index].descriptionId = value

      setExample(state)
    },
    [exmaple]
  )

  const handleChangeExampleEn = useCallback(
    (e, index) => {
      const { value } = e.target
      const state = [...exmaple]
      state[index].descriptionEn = value

      setExample(state)
    },
    [exmaple]
  )

  const onResetForm = useCallback(() => {
    setValueNameId("")
    setValueNameEn("")
    setValueSubNameId("")
    setValueSubNameEn("")
    setValueAccessType("0")
    setDefinition([
      {
        descriptionId: "",
        descriptionEn: "",
      },
    ])
    setExample([
      {
        descriptionId: "",
        descriptionEn: "",
      },
    ])
  }, [])

  const onSubmit = useCallback(() => {
    const dataArr = []
    for (let i = 0; i < definition.length; i++) {
      dataArr.push({
        description_id: definition[i].descriptionId,
        description_en: definition[i].descriptionEn,
      })
    }

    const exampleArr = []
    for (let i = 0; i < exmaple.length; i++) {
      if (exmaple[i].value !== "") {
        exampleArr.push({
          description_id: exmaple[i].descriptionId,
          description_en: exmaple[i].descriptionEn,
        })
      }
    }

    const payload = {
      nameId: valueNameId,
      nameEn: valueNameEn,
      subNameId: valueSubNameId,
      subNameEn: valueSubNameEn,
      accessType: valueAccessType,
      definitions: dataArr,
      examples: exampleArr,
    }

    ApiAddDictionary(payload).then(response => {
      if (response) {
        const { status, message } = response

        if (status == 201) {
          onResetForm()
          toastr.success(message, "Success")
        }
      }
    })
  }, [
    valueNameId,
    valueNameEn,
    valueSubNameId,
    valueSubNameEn,
    valueAccessType,
    definition,
    exmaple,
    onResetForm,
  ])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Tambah")}
            breadcrumbItem={props.t("Kamus")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="mx-auto col-10">
                      <Form>
                        <div className="mb-3 row">
                          <Col md="6">
                            <Label>
                              Nama ID <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={valueNameId}
                              type="text"
                              className="form-control"
                              onChange={handleChangeNameId}
                              placeholder="masukkan nama kamus indonesia"
                            />
                          </Col>

                          <Col md="6">
                            <Label>
                              Nama EN <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={valueNameEn}
                              type="text"
                              className="form-control"
                              onChange={handleChangeNameEn}
                              placeholder="masukkan nama kamus inggris"
                            />
                          </Col>
                        </div>

                        <div className="mb-3 row">
                          <Col md="6">
                            <Label>Sub Nama ID</Label>
                            <Input
                              value={valueSubNameId}
                              type="text"
                              className="form-control"
                              onChange={handleChangeSubNameId}
                              placeholder="masukkan sub nama kamus indonesia"
                            />
                          </Col>

                          <Col md="6">
                            <Label>Sub Nama EN</Label>
                            <Input
                              value={valueSubNameEn}
                              type="text"
                              className="form-control"
                              onChange={handleChangeSubNameEn}
                              placeholder="masukkan sub nama kamus inggris"
                            />
                          </Col>
                        </div>

                        <div className="mb-3 row">
                          <Col md="12">
                            <Label>
                              Tipe Akses <span className="text-danger">*</span>
                            </Label>
                            <select
                              value={valueAccessType}
                              className="form-select"
                              onChange={onChangeAccessType}
                            >
                              <option value="0">Free</option>
                              <option value="1">Premium</option>
                            </select>
                          </Col>
                        </div>

                        <div className="mb-3 ">
                          {definition.map((d, i) => {
                            return (
                              <Row key={i} className="mb-3">
                                <Col md={11}>
                                  <Row>
                                    <Col md={6}>
                                      {i === 0 ? (
                                        <Label>
                                          Definisi ID{" "}
                                          <span className="text-danger">*</span>
                                        </Label>
                                      ) : null}

                                      <Input
                                        value={d.descriptionId}
                                        type="text"
                                        className="form-control"
                                        onChange={e =>
                                          handleChangeBenefitId(e, i)
                                        }
                                        placeholder="definisi kamus indonesia"
                                      />
                                    </Col>

                                    <Col md={6}>
                                      {i === 0 ? (
                                        <Label>
                                          Definisi EN{" "}
                                          <span className="text-danger">*</span>
                                        </Label>
                                      ) : null}

                                      <Input
                                        value={d.descriptionEn}
                                        type="text"
                                        className="form-control"
                                        onChange={e =>
                                          handleChangeBenefitEn(e, i)
                                        }
                                        placeholder="definisi kamus inggris"
                                      />
                                    </Col>
                                  </Row>
                                </Col>

                                {i !== 0 ? (
                                  <Col
                                    md={1}
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Button
                                      color="danger"
                                      onClick={() =>
                                        handleRemoveColumnDefinisi(i)
                                      }
                                    >
                                      <i className="bx bx-minus"></i>
                                    </Button>
                                  </Col>
                                ) : null}
                              </Row>
                            )
                          })}

                          <Col className="mt-2">
                            <Button
                              color="primary"
                              onClick={handleAddColumnDefinisi}
                            >
                              Tambah Kolom
                            </Button>
                          </Col>
                        </div>

                        <div className="mb-3 ">
                          {exmaple.map((d, i) => {
                            return (
                              <Row key={i} className="mb-3">
                                <Col md={11}>
                                  <Row>
                                    <Col md={6}>
                                      {i === 0 ? (
                                        <Label>Contoh ID</Label>
                                      ) : null}

                                      <Input
                                        value={d.descriptionId}
                                        type="text"
                                        className="form-control"
                                        onChange={e =>
                                          handleChangeExampleId(e, i)
                                        }
                                        placeholder="contoh definisi indonesia"
                                      />
                                    </Col>

                                    <Col md={6}>
                                      {i === 0 ? (
                                        <Label>Contoh EN</Label>
                                      ) : null}

                                      <Input
                                        value={d.descriptionEn}
                                        type="text"
                                        className="form-control"
                                        onChange={e =>
                                          handleChangeExampleEn(e, i)
                                        }
                                        placeholder="contoh definisi inggris"
                                      />
                                    </Col>
                                  </Row>
                                </Col>

                                {i !== 0 ? (
                                  <Col md={1}>
                                    <Button
                                      color="danger"
                                      onClick={() =>
                                        handleRemoveColumnExample(i)
                                      }
                                    >
                                      <i className="bx bx-minus"></i>
                                    </Button>
                                  </Col>
                                ) : null}
                              </Row>
                            )
                          })}

                          <Col className="mt-2">
                            <Button
                              color="primary"
                              onClick={handleAddColumnExample}
                            >
                              Tambah Kolom
                            </Button>
                          </Col>
                        </div>
                      </Form>
                    </Col>

                    <Col className="mx-auto col-10">
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/content/dictionary"
                          className="btn btn-danger mb-2 me-2"
                        >
                          <i className="fas fa-arrow-left"></i> Kembali
                        </Link>
                        <Button
                          type="button"
                          color="primary"
                          className="mb-2"
                          onClick={onSubmit}
                          disabled={disabledButton}
                        >
                          <i className="fas fa-save"></i> Simpan
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CreateDictionary.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(CreateDictionary)
