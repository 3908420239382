export const listDataScience = [
  {
    id: 1,
    label: "Ekonomi",
  },
  {
    id: 2,
    label: "Ekonomi Syariah",
  },
]

export const listDataTopic = [
  {
    id: 1,
    sciendId: 1,
    label: "Ekonomi Makro",
  },
  {
    id: 2,
    sciendId: 1,
    label: "Ekonomi Mikro",
  },
  {
    id: 3,
    sciendId: 1,
    label: "Ekonomi Internasional",
  },
  {
    id: 4,
    sciendId: 2,
    label: "Pengantar Ekonomi Islam",
  },
  {
    id: 5,
    sciendId: 2,
    label: "Ekonomi Zakat & Wakaf",
  },
  {
    id: 6,
    sciendId: 2,
    label: "Ekonomi Makro Islam",
  },
]

export const pagePagination = 1
export const limitPagination = 10

export const listOfMonth = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "July",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
]
