import axios from "../lib/axios"

export const ApiGetTopic = async (
  scienceId = "",
  search = "",
  page = "",
  limit = ""
) => {
  const response = axios.get(
    `/content/topic?scienceId=${scienceId}&search=${search}&page=${page}&limit=${limit}`
  )
  return response
}

export const ApiAddTopic = async payload => {
  const response = axios.post(`/content/topic`, payload)
  return response
}

export const ApiDetailTopic = async id => {
  const response = axios.get(`/content/topic/${id}`)
  return response
}

export const ApiUpdateTopic = async (id, payload) => {
  const response = axios.put(`/content/topic/${id}`, payload)
  return response
}

export const ApiDeleteTopic = async id => {
  console.log("id", id)
  const response = axios.delete(`/content/topic/${id}`)
  return response
}

export const ApiStatusLeatestTopic = async (id, payload) => {
  const response = axios.patch(`/content/topic/leatest/${id}`, payload)
  return response
}
