import axios from "../lib/axios"

export const ApiGetScience = async (search, page) => {
  const response = axios.get(`/content/science`)
  return response
}

export const ApiAddScience = async payload => {
  const response = axios.post(`/content/science`, payload)
  return response
}

export const ApiDetailScience = async id => {
  const response = axios.get(`/content/science/${id}`)
  return response
}

export const ApiUpdateScience = async (id, payload) => {
  const response = axios.put(`/content/science/${id}`, payload)
  return response
}

export const ApiDeleteScience = async id => {
  const response = axios.delete(`/content/science/${id}`)
  return response
}

export const ApiStatusLeatestScience = async (id, payload) => {
  const response = axios.patch(`/content/science/leatest/${id}`, payload)
  return response
}
