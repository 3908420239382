import axios from "../lib/axios"

export const ApiGetSubscription = async (
  page = "",
  limit = "",
  studentId = "",
  active = "",
  search = ""
) => {
  const response = axios.get(
    `/subscription?page=${page}&limit=${limit}&studentId=${studentId}&active=${active}&search=${search}`
  )
  return response
}

export const ApiGenerateInvoiceSubscription = async payload => {
  const response = axios.post(`/subscription/generate-invoice`, payload)
  return response
}

export const ApiAddSubscription = async payload => {
  const response = axios.post(`/subscription`, payload)
  return response
}

// export const ApiDetailPricingPlan = async id => {
//   const response = axios.get(`/pricing-plan/${id}`)
//   return response
// }

// export const ApiUpdatePricingPlan = async (id, payload) => {
//   const response = axios.put(`/pricing-plan/${id}`, payload)
//   return response
// }

// export const ApiDeletePricingPlan = async id => {
//   const response = axios.delete(`/pricing-plan/${id}`)
//   return response
// }
