import PropTypes from "prop-types"
import React, { useState, useCallback, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap"

import { Link, useParams } from "react-router-dom"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { ApiGetScience } from "../../../api/science"
import { ApiGetTopic } from "../../../api/topic"
import { ApiUpdateMaterial, ApiDetailMaterial } from "../../../api/material"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import PDFViewer from "../../../components/PDFViewer"

import { baseUrl } from "../../../config/env"

//i18n
import { withTranslation } from "react-i18next"

const UpdateTopic = props => {
  //meta title
  document.title = "Edit Materi | Economates"
  const params = useParams()
  const [valueScienceId, setValueScienceId] = useState("")

  //topic
  const [valueTopicId, setValueTopicId] = useState("")
  const [dataTopic, setDataTopic] = useState([])

  //name
  const [valueNameId, setValueNameId] = useState("")
  const [valueNameEn, setValueNameEn] = useState("")

  //description
  const [valueDescriptionId, setValueDescriptionId] = useState("")
  const [valueDescriptionEn, setValueDescriptionEn] = useState("")

  //bobot point
  const [valueBobotPoint, setValueBobotPoint] = useState(1)

  // access type
  const [valueAccessType, setValueAccessType] = useState("0")

  //file
  const [fileId, setFileId] = useState("")
  const [urlPdfPreviewId, setUrlPdfPreviewId] = useState("")
  const [oldUrlPdfPreviewId, setOldUrlPdfPreviewId] = useState("")

  const [fileEn, setFileEn] = useState("")
  const [urlPdfPreviewEn, setUrlPdfPreviewEn] = useState("")
  const [oldUrlPdfPreviewEn, setOldUrlPdfPreviewEn] = useState("")

  const [sourceScience, setSourceScience] = useState([])
  const [sourceTopic, setSourceTopic] = useState([])

  const [disabledButton, setDisabledButton] = useState(true)

  useEffect(() => {
    ApiDetailMaterial(params.id).then(response => {
      if (response) {
        const { status, result } = response

        if (status == 200) {
          setValueScienceId(result.data.science.id)
          setValueTopicId(result.data.topic.id)
          setValueNameId(
            result.data.name_id ? result.data.name_id : result.data.name
          )
          setValueNameEn(result.data.name_en ? result.data.name_en : "")
          setValueDescriptionId(
            result.data.description_id
              ? result.data.description_id
              : result.data.description
          )
          setValueDescriptionEn(
            result.data.description_en ? result.data.description_en : ""
          )
          setValueBobotPoint(result.data.bobot_point)
          setValueAccessType(result.data.access_type)

          if (result.data.path_pdf_file_id) {
            setOldUrlPdfPreviewId(`${baseUrl}/${result.data.path_pdf_file_id}`)
          }

          if (result.data.path_pdf_file_en) {
            setOldUrlPdfPreviewEn(`${baseUrl}/${result.data.path_pdf_file_en}`)
          }
        }
      }
    })
  }, [params.id])

  useEffect(() => {
    const filterDataTopic = sourceTopic.filter(
      d => d.scienceId === Number(valueScienceId)
    )
    setDataTopic(filterDataTopic)
  }, [valueScienceId, sourceTopic])

  useEffect(() => {
    if (
      valueNameId === "" ||
      valueNameEn === "" ||
      valueDescriptionId === "" ||
      valueDescriptionEn === "" ||
      valueScienceId === "" ||
      valueTopicId === ""
    ) {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
  }, [
    valueNameId,
    valueNameEn,
    valueDescriptionId,
    valueDescriptionEn,
    valueScienceId,
    valueTopicId,
  ])

  useEffect(() => {
    ApiGetScience().then(response => {
      if (response) {
        const { status, result } = response

        if (status === 200) {
          const dataArr = []
          for (let i = 0; i < result.data.length; i++) {
            dataArr.push({
              id: result.data[i].content_science_id,
              label: `${
                result.data[i].name_id
                  ? result.data[i].name_id
                  : result.data[i].name
              }`,
            })
          }
          setSourceScience(dataArr)
        }
      }
    })
  }, [])

  useEffect(() => {
    ApiGetTopic().then(response => {
      if (response) {
        const { status, result } = response

        if (status === 200) {
          const dataArr = []
          for (let i = 0; i < result.data.length; i++) {
            dataArr.push({
              id: result.data[i].topicId,
              label: `${
                result.data[i].name_id
                  ? result.data[i].name_id
                  : result.data[i].name
              }`,
              scienceId: result.data[i].science.id,
            })
          }
          setSourceTopic(dataArr)
        }
      }
    })
  }, [])

  const onChangeScience = useCallback(
    e => {
      let { value } = e.target
      setValueScienceId(value)
      setValueTopicId("")
    },
    [sourceTopic]
  )

  const onChangeTopic = useCallback(e => {
    let { value } = e.target
    setValueTopicId(value)
  }, [])

  const onChangeNameId = useCallback(e => {
    let { value } = e.target
    setValueNameId(value)
  }, [])

  const onChangeNameEn = useCallback(e => {
    let { value } = e.target
    setValueNameEn(value)
  }, [])

  const onChangeDescriptionId = useCallback(e => {
    let { value } = e.target
    setValueDescriptionId(value)
  }, [])

  const onChangeDescriptionEn = useCallback(e => {
    let { value } = e.target
    setValueDescriptionEn(value)
  }, [])

  const onChangeBobotPoint = useCallback(e => {
    let { value } = e.target

    const valueNumber = Number(value)
    if (isNaN(valueNumber)) return false

    setValueBobotPoint(value)
  }, [])

  const onChangeAccessType = useCallback(e => {
    let { value } = e.target
    setValueAccessType(value)
  }, [])

  const onChangeFileId = useCallback(e => {
    const value = e.target.files[0]
    if (value) {
      setFileId(value)
      setUrlPdfPreviewId(URL.createObjectURL(value))
      // if (value.size > 1000000) {
      //   setErrorNewBackgroundGambar("background tidak boleh lebih dari 1 MB")
      // } else {
      //   setErrorNewBackgroundGambar("")
      // }
    } else {
      setFileId("")
      setUrlPdfPreviewId("")
    }
  }, [])

  const onChangeFileEn = useCallback(e => {
    const value = e.target.files[0]
    if (value) {
      setFileEn(value)
      setUrlPdfPreviewEn(URL.createObjectURL(value))
      // if (value.size > 1000000) {
      //   setErrorNewBackgroundGambar("background tidak boleh lebih dari 1 MB")
      // } else {
      //   setErrorNewBackgroundGambar("")
      // }
    } else {
      setFileEn("")
      setUrlPdfPreviewEn("")
    }
  }, [])

  const onSubmit = useCallback(() => {
    const formdata = new FormData()
    formdata.set("scienceId", valueScienceId)
    formdata.set("topicId", valueTopicId)
    formdata.set("name_id", valueNameId)
    formdata.set("name_en", valueNameEn)
    formdata.set("description_id", valueDescriptionId)
    formdata.set("description_en", valueDescriptionEn)
    formdata.set("bobotPoint", parseFloat(valueBobotPoint))
    formdata.set("file_id", fileId)
    formdata.set("file_en", fileEn)
    formdata.set("accessType", valueAccessType)
    // const payload = {
    //   scienceId: valueScienceId,
    //   topicId: valueTopicId,
    //   name: valueName,
    //   bobotPoint: parseFloat(valueBobotPoint),
    // }
    ApiUpdateMaterial(params.id, formdata).then(response => {
      if (response) {
        const { status, message } = response
        if (status == 200) {
          toastr.success(message, "Success")
        }
      }
    })
  }, [
    params.id,
    valueNameId,
    valueNameEn,
    valueDescriptionId,
    valueDescriptionEn,
    valueScienceId,
    valueTopicId,
    valueBobotPoint,
    fileId,
    fileEn,
    valueAccessType,
  ])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Edit")}
            breadcrumbItem={props.t("Materi")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="mx-auto col-10">
                      <Form id="form-update-material">
                        <div className="mb-3 ">
                          <Label>
                            Bidang Ilmu <span className="text-danger">*</span>
                          </Label>
                          <select
                            value={valueScienceId}
                            className="form-select"
                            onChange={onChangeScience}
                          >
                            <option value="">Pilih Bidang Ilmu</option>
                            {sourceScience.map((d, i) => (
                              <option value={d.id} key={i}>
                                {d.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="mb-3 ">
                          <Label>
                            Topik <span className="text-danger">*</span>
                          </Label>
                          <select
                            value={valueTopicId}
                            className="form-select"
                            onChange={onChangeTopic}
                          >
                            <option value="">Pilih Topik</option>
                            {dataTopic.map((d, i) => (
                              <option value={d.id} key={i}>
                                {d.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <Row>
                          <Col md="6" className="mb-3">
                            <Label>
                              Nama Materi ID{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={valueNameId}
                              type="text"
                              className="form-control"
                              onChange={onChangeNameId}
                              placeholder="masukkan nama materi indonesia"
                            />
                          </Col>

                          <Col md="6" className="mb-3">
                            <Label>
                              Nama Materi EN{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={valueNameEn}
                              type="text"
                              className="form-control"
                              onChange={onChangeNameEn}
                              placeholder="masukkan nama materi inggris"
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col md="6" className="mb-3">
                            <Label>
                              Deskripsi Materi ID{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <textarea
                              value={valueDescriptionId}
                              onChange={onChangeDescriptionId}
                              className="form-control"
                              rows="3"
                              placeholder="masukkan deskripsi materi indonesia"
                            />
                          </Col>

                          <Col md="6" className="mb-3">
                            <Label>
                              Deskripsi Materi EN{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <textarea
                              value={valueDescriptionEn}
                              onChange={onChangeDescriptionEn}
                              className="form-control"
                              rows="3"
                              placeholder="masukkan deskripsi materi inggris"
                            />
                          </Col>
                        </Row>

                        <div className="mb-3 "></div>

                        <div className="mb-3 col-md-2">
                          <Label>
                            Bobot Poin <span className="text-danger">*</span>
                          </Label>
                          <Input
                            value={valueBobotPoint}
                            type="text"
                            className="form-control"
                            onChange={onChangeBobotPoint}
                            placeholder="bobot poin"
                          />
                        </div>

                        <div className="mb-3 col-md-2">
                          <Label>
                            Tipe Akses <span className="text-danger">*</span>
                          </Label>
                          <select
                            value={valueAccessType}
                            className="form-select"
                            onChange={onChangeAccessType}
                          >
                            <option value="0">Free</option>
                            <option value="1">Premium</option>
                          </select>
                        </div>

                        <Row>
                          <Col md="6" className="mb-3">
                            <div className="mb-3 ">
                              <Label>File ID</Label>
                              <Input
                                type="file"
                                className="form-control"
                                onChange={onChangeFileId}
                                accept=".pdf"
                              />
                            </div>

                            {urlPdfPreviewId || oldUrlPdfPreviewId ? (
                              <div className="d-flex">
                                <PDFViewer
                                  url={
                                    urlPdfPreviewId
                                      ? urlPdfPreviewId
                                      : oldUrlPdfPreviewId
                                  }
                                />
                              </div>
                            ) : null}
                          </Col>

                          <Col md="6" className="mb-3">
                            <div className="mb-3 ">
                              <Label>File EN</Label>
                              <Input
                                type="file"
                                className="form-control"
                                onChange={onChangeFileEn}
                                accept=".pdf"
                              />
                            </div>

                            {urlPdfPreviewEn || oldUrlPdfPreviewEn ? (
                              <div className="d-flex">
                                <PDFViewer
                                  url={
                                    urlPdfPreviewEn
                                      ? urlPdfPreviewEn
                                      : oldUrlPdfPreviewEn
                                  }
                                />
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                      </Form>
                    </Col>

                    <Col className="mx-auto col-10">
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/content/material"
                          className="btn btn-danger mb-2 me-2"
                        >
                          <i className="fas fa-arrow-left"></i> Kembali
                        </Link>
                        <Button
                          type="button"
                          color="primary"
                          className="mb-2"
                          onClick={onSubmit}
                          disabled={disabledButton}
                        >
                          <i className="fas fa-save"></i> Simpan
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UpdateTopic.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(UpdateTopic)
