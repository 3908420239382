import axios from "../lib/axios"

export const ApiGetMaterial = async (
  scienceId = "",
  topicId = "",
  search = "",
  page = "",
  limit = ""
) => {
  const response = axios.get(
    `/content/material?scienceId=${scienceId}&topicId=${topicId}&search=${search}&page=${page}&limit=${limit}`
  )
  return response
}

export const ApiAddMaterial = async payload => {
  const response = axios.post(`/content/material`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  return response
}

export const ApiDetailMaterial = async id => {
  const response = axios.get(`/content/material/${id}`)
  return response
}

export const ApiUpdateMaterial = async (id, payload) => {
  const response = axios.put(`/content/material/${id}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  return response
}

export const ApiDeleteMaterial = async id => {
  const response = axios.delete(`/content/material/${id}`)
  return response
}

export const ApiActiveMaterial = async (id, payload) => {
  const response = axios.patch(`/content/material/active/${id}`, payload)
  return response
}

export const ApiStatusLeatestMaterial = async (id, payload) => {
  const response = axios.patch(`/content/material/leatest/${id}`, payload)
  return response
}
