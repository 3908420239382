import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Input,
  CardTitle,
  Button,
  Spinner,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Pagination from "../../components/Pagination"

//i18n
import { withTranslation } from "react-i18next"

import {
  ApiGetActiveStudent,
  ApiGetNoActiveStudent,
  ApiGetTotalAllSummaryStudent,
  ApiGetTotalAllLastOpenAppStudent,
  ApiGetSummaryRegisteredStudent,
} from "../../api/student"

import Table from "../../components/Table"

import { limitPagination } from "../../constants/data"

import dayjs from "../../lib/dayjs"

import DashedLine from "./DashedLine"
import Apaexlinecolumn from "./ColumnChart"

import { listOfMonth } from "../../constants/data"

const Dashboard = props => {
  //meta title
  document.title = "Dashboard | Economates"

  const [dataStudentActive, setDataStudentActive] = useState([])
  const [isLoadingStudentActive, setIsLoadingStudentActive] = useState(true)
  const [paginationStudentActive, setPaginationStudentActive] = useState({
    totalPage: 0,
    currentPage: 1,
    nextPage: 0,
    prevPage: 0,
  })
  const [valueSearchStudentActive, setValueSearchStudentActive] = useState("")

  const [dataStudentNoActive, setDataStudentNoActive] = useState([])
  const [isLoadingStudentNoActive, setIsLoadingStudentNoActive] = useState(true)
  const [paginationStudentNoActive, setPaginationStudentNoActive] = useState({
    totalPage: 0,
    currentPage: 1,
    nextPage: 0,
    prevPage: 0,
  })
  const [valueSearchStudentNoActive, setValueSearchStudentNoActive] =
    useState("")

  const [totalAllData, setTotalAllData] = useState({})

  // last open app
  const [isLoadingOpenLastApp, setIsLoadingOpenLastApp] = useState(false)
  const [filterLastOpenApp, setFilterLastOpenApp] = useState({
    month: "",
    year: "",
  })
  const [dataLastOpenApp, setDataLastOpenApp] = useState({
    series: [
      {
        name: "Pelajar",
        data: [],
      },
    ],
    categories: [],
  })

  // registered
  const [dataSummaryRegistered, setDataSummaryRegistered] = useState({
    series: [
      {
        name: "Pelajar",
        data: [],
      },
    ],
    categories: [],
  })

  useEffect(() => {
    ApiGetTotalAllSummaryStudent().then(response => {
      if (response) {
        const { status, result } = response
        if (status === 200) {
          setTotalAllData(result.data)
        }
      }
    })

    ApiGetSummaryRegisteredStudent().then(response => {
      if (response) {
        const { status, result } = response
        if (status === 200) {
          const dataArr = []
          for (let i = 0; i < listOfMonth.length; i++) {
            if (result.data[i]) {
              dataArr.push(result.data[i].total)
            } else {
              dataArr.push(0)
            }
          }

          setDataSummaryRegistered(oldState => {
            const state = { ...oldState }

            state.series[0] = {
              ...state.series[0],
              data: dataArr,
            }
            state.categories = listOfMonth

            return state
          })
        }
      }
    })

    const date = new Date()
    setFilterLastOpenApp(oldState => ({
      ...oldState,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    }))
  }, [])

  useEffect(() => {
    setIsLoadingOpenLastApp(true)

    if (filterLastOpenApp.month && filterLastOpenApp.year) {
      ApiGetTotalAllLastOpenAppStudent(
        filterLastOpenApp.month,
        filterLastOpenApp.year
      ).then(response => {
        if (response) {
          const { status, result } = response
          if (status === 200) {
            const dataArr = []
            const categoriesArr = []
            for (let i = 0; i < result.data.length; i++) {
              dataArr.push(result.data[i].total)
              categoriesArr.push(
                dayjs(result.data[i].date).format("DD MMM YYYY")
              )
            }

            setDataLastOpenApp(oldState => {
              const state = { ...oldState }

              state.series[0] = {
                ...state.series[0],
                data: dataArr,
              }
              state.categories = categoriesArr

              return state
            })
          }

          setIsLoadingOpenLastApp(false)
        }
      })
    }
  }, [filterLastOpenApp])

  const handleGetDataStudentActive = useCallback(() => {
    ApiGetActiveStudent(
      valueSearchStudentActive,
      paginationStudentActive.currentPage,
      limitPagination
    ).then(response => {
      if (response) {
        const { status, result } = response

        if (status === 200) {
          const dataArr = []

          for (let i = 0; i < result.data.length; i++) {
            dataArr.push({
              id: result.data[i].student_id,
              name: result.data[i].name,
              email: result.data[i].email,
              createdAt: dayjs(result.data[i].created_at).format(
                "DD MMMM YYYY HH:mm:ss"
              ),
              lastLoginDate: dayjs(result.data[i].last_login_date).format(
                "DD MMMM YYYY HH:mm:ss"
              ),
            })
          }

          if (!result.pagination.next && !result.pagination.prev) {
            setPaginationStudentActive(oldState => ({
              ...oldState,
              totalPage: result.totalPage,
            }))
          } else {
            if (result.pagination.next) {
              setPaginationStudentActive(oldState => ({
                ...oldState,
                nextPage: result.pagination.next.page,
              }))
            } else {
              setPaginationStudentActive(oldState => ({
                ...oldState,
                nextPage: 0,
              }))
            }

            if (result.pagination.prev) {
              setPaginationStudentActive(oldState => ({
                ...oldState,
                prevPage: result.pagination.prev.page,
              }))
            } else {
              setPaginationStudentActive(oldState => ({
                ...oldState,
                prevPage: 0,
              }))
            }

            setPaginationStudentActive(oldState => ({
              ...oldState,
              totalPage: result.totalPage,
            }))
          }

          setDataStudentActive(dataArr)
        }
      }

      setIsLoadingStudentActive(false)
    })
  }, [valueSearchStudentActive, paginationStudentActive])

  useEffect(() => {
    setIsLoadingStudentActive(true)
    let timeout = setTimeout(() => {
      handleGetDataStudentActive()
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [valueSearchStudentActive, paginationStudentActive.currentPage])

  const handleGetDataStudentNoActive = useCallback(() => {
    ApiGetNoActiveStudent(
      valueSearchStudentNoActive,
      paginationStudentNoActive.currentPage,
      limitPagination
    ).then(response => {
      if (response) {
        const { status, result } = response

        if (status === 200) {
          const dataArr = []

          for (let i = 0; i < result.data.length; i++) {
            dataArr.push({
              id: result.data[i].student_id,
              name: result.data[i].name,
              email: result.data[i].email,
              createdAt: dayjs(result.data[i].created_at).format(
                "DD MMMM YYYY HH:mm:ss"
              ),
              lastLoginDate: result.data[i].last_login_date
                ? dayjs(result.data[i].last_login_date).format(
                    "DD MMMM YYYY HH:mm:ss"
                  )
                : "-",
            })
          }

          if (!result.pagination.next && !result.pagination.prev) {
            setPaginationStudentNoActive(oldState => ({
              ...oldState,
              totalPage: result.totalPage,
            }))
          } else {
            if (result.pagination.next) {
              setPaginationStudentNoActive(oldState => ({
                ...oldState,
                nextPage: result.pagination.next.page,
              }))
            } else {
              setPaginationStudentNoActive(oldState => ({
                ...oldState,
                nextPage: 0,
              }))
            }

            if (result.pagination.prev) {
              setPaginationStudentNoActive(oldState => ({
                ...oldState,
                prevPage: result.pagination.prev.page,
              }))
            } else {
              setPaginationStudentNoActive(oldState => ({
                ...oldState,
                prevPage: 0,
              }))
            }

            setPaginationStudentNoActive(oldState => ({
              ...oldState,
              totalPage: result.totalPage,
            }))
          }

          setDataStudentNoActive(dataArr)
        }
      }

      setIsLoadingStudentNoActive(false)
    })
  }, [valueSearchStudentNoActive, paginationStudentNoActive])

  useEffect(() => {
    setIsLoadingStudentNoActive(true)
    let timeout = setTimeout(() => {
      handleGetDataStudentNoActive()
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [valueSearchStudentNoActive, paginationStudentNoActive.currentPage])

  const onChangeFilterLastOpenAppMonth = useCallback(e => {
    const value = e.target.value
    setFilterLastOpenApp(oldState => ({
      ...oldState,
      month: value,
    }))
  }, [])

  const onChangeFilterLastOpenAppYear = useCallback(e => {
    const value = e.target.value
    setFilterLastOpenApp(oldState => ({
      ...oldState,
      year: value,
    }))
  }, [])

  const onChangeSearchStudentActive = useCallback(e => {
    let { value } = e.target
    setValueSearchStudentActive(value)
    setPaginationStudentActive({
      totalPage: 0,
      currentPage: 1,
      nextPage: 0,
      prevPage: 0,
    })
  }, [])

  const onChangeSearchStudentNoActive = useCallback(e => {
    let { value } = e.target
    setValueSearchStudentNoActive(value)
    setPaginationStudentNoActive({
      totalPage: 0,
      currentPage: 1,
      nextPage: 0,
      prevPage: 0,
    })
  }, [])

  const reportsTotalStudent = useMemo(() => {
    return [
      {
        title: "Total Semua Pelajar",
        bg: "bg-primary",
        iconClass: "bx-user",
        description: totalAllData?.total_all ? totalAllData?.total_all : "0",
      },
      {
        title: "Total Pelajar Aktif (<= 1 Bulan)",
        bg: "bg-success",
        iconClass: "bx-user-check",
        description: totalAllData?.total_active
          ? totalAllData?.total_active
          : "0",
      },
      {
        title: "Total Pelajar Tidak Aktif (>= 1 Bulan)",
        bg: "bg-danger",
        iconClass: "bx-user-x",
        description: totalAllData?.total_no_active
          ? totalAllData?.total_no_active
          : "0",
      },
    ]
  }, [totalAllData])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            {reportsTotalStudent.map((report, key) => (
              <Col md="4" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">{report.title}</p>
                        <h4 className="mb-0">{report.description}</h4>
                      </div>
                      <div
                        className={`avatar-sm rounded-circle ${report.bg} align-self-center mini-stat-icon`}
                      >
                        <span
                          className={`avatar-title rounded-circle ${report.bg}`}
                        >
                          <i
                            className={
                              "bx " + report.iconClass + " font-size-24"
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <CardTitle className="m-0">Pelajar Terdaftar</CardTitle>

                    <Button className="btn-light active">2024</Button>
                  </div>
                  <Apaexlinecolumn
                    dataColors='["--bs-success"]'
                    dataChart={dataSummaryRegistered}
                  />
                </CardBody>
              </Card>
            </Col>

            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <CardTitle>Pelajar Membuka Aplikasi</CardTitle>
                    </div>

                    <div
                      className="col-md-6 d-flex justify-content-md-end"
                      // style={{ backgroundColor: "red" }}
                    >
                      <div
                        className="col-md-8 row"
                        // style={{ backgroundColor: "blue" }}
                      >
                        <div className="col-6 col-md-7">
                          <div className="input-group input-group-sm">
                            <select
                              className="form-select form-select-sm"
                              value={filterLastOpenApp.month}
                              onChange={onChangeFilterLastOpenAppMonth}
                            >
                              <option value="1">Januari</option>
                              <option value="2">Februari</option>
                              <option value="3">Maret</option>
                              <option value="4">April</option>
                              <option value="5">Mei</option>
                              <option value="6">Juni</option>
                              <option value="7">Juli</option>
                              <option value="8">Agustus</option>
                              <option value="9">September</option>
                              <option value="10">Oktober</option>
                              <option value="11">November</option>
                              <option value="12">Desember</option>
                            </select>
                            <label className="input-group-text">Bulan</label>
                          </div>
                        </div>

                        <div className="col-6 col-md-5">
                          <div className="input-group input-group-sm">
                            <select
                              className="form-select form-select-sm"
                              value={filterLastOpenApp.year}
                              onChange={onChangeFilterLastOpenAppYear}
                            >
                              <option value="2024">2024</option>
                              <option value="2025">2025</option>
                              <option value="2026">2026</option>
                            </select>
                            <label className="input-group-text">Tahun</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {isLoadingOpenLastApp && (
                    <div
                      className="d-flex justify-content-center align-items-center text-primary"
                      style={{
                        minHeight: 300,
                      }}
                    >
                      <Spinner />
                    </div>
                  )}

                  {!isLoadingOpenLastApp && (
                    <Apaexlinecolumn
                      dataColors='["--bs-primary", "--bs-danger", "--bs-success"]'
                      dataChart={dataLastOpenApp}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>

            {/* <Col md={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Dashed Line</CardTitle>
                  <DashedLine dataColors='["--bs-primary", "--bs-danger", "--bs-success"]' />
                </CardBody>
              </Card>
            </Col> */}
          </Row>

          {/* <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Pelajar Membuka Aplikasi
                  </CardTitle>
                  <Apaexlinecolumn
                    dataColors='["--bs-primary", "--bs-danger", "--bs-success"]'
                    dataChart={dataLastOpenApp}
                  />
                </CardBody>
              </Card>
            </Col>

            <Col md={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Dashed Line</CardTitle>
                  <DashedLine dataColors='["--bs-primary", "--bs-danger", "--bs-success"]' />
                </CardBody>
              </Card>
            </Col>
          </Row> */}

          <Row>
            <Col md="6">
              <Card>
                <CardBody
                  style={{
                    minHeight: 700,
                  }}
                >
                  <div className="mb-4 card-title">
                    Pelajar Aktif ({`<`}= 1 Bulan)
                  </div>

                  <Row className="mb-3">
                    <Col md="4">
                      <Input
                        value={valueSearchStudentActive}
                        onChange={onChangeSearchStudentActive}
                        placeholder="cari pelajar..."
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <Table
                        column={[
                          "Nama",
                          "Email",
                          "Tanggal Dibuat",
                          "Tanggal Buka Aplikasi",
                        ]}
                        row={dataStudentActive}
                        isLoading={isLoadingStudentActive}
                      />
                    </Col>
                  </Row>

                  {!isLoadingStudentActive ? (
                    <Row className="mt-3">
                      <Pagination
                        totalPage={paginationStudentActive.totalPage}
                        currentPage={paginationStudentActive.currentPage}
                        nextPage={paginationStudentActive.nextPage}
                        prevPage={paginationStudentActive.prevPage}
                        setPagination={setPaginationStudentActive}
                      />
                    </Row>
                  ) : null}
                </CardBody>
              </Card>
            </Col>

            <Col md="6">
              <Card>
                <CardBody
                  style={{
                    minHeight: 700,
                  }}
                >
                  <div className="mb-4 card-title">
                    Pelajar Tidak Aktif ({">"}= 1 Bulan)
                  </div>

                  <Row className="mb-3">
                    <Col md="4">
                      <Input
                        value={valueSearchStudentNoActive}
                        onChange={onChangeSearchStudentNoActive}
                        placeholder="cari pelajar..."
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <Table
                        column={[
                          "Nama",
                          "Email",
                          "Tanggal Dibuat",
                          "Tanggal Buka Aplikasi",
                        ]}
                        row={dataStudentNoActive}
                        isLoading={isLoadingStudentNoActive}
                      />
                    </Col>
                  </Row>

                  {!isLoadingStudentNoActive ? (
                    <Row className="mt-3">
                      <Pagination
                        totalPage={paginationStudentNoActive.totalPage}
                        currentPage={paginationStudentNoActive.currentPage}
                        nextPage={paginationStudentNoActive.nextPage}
                        prevPage={paginationStudentNoActive.prevPage}
                        setPagination={setPaginationStudentNoActive}
                      />
                    </Row>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
