import axios from "../lib/axios"

export const ApiLoadUser = async () => {
  const response = axios.get(`/auth`)
  return response
}

export const ApiSigninUser = async payload => {
  const response = axios.post(`/auth/signin`, payload)
  return response
}
