import React from "react"
import { Navigate } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// User
import UserAdmin from "../pages/User/Admin"
import UserPelajar from "../pages/User/Pelajar"

// Kontent
import ContentIlmu from "../pages/Content/Science"
import CreateContentIlmu from "../pages/Content/Science/Create"
import UpdateContentIlmu from "../pages/Content/Science/Update"
import ContentTopic from "../pages/Content/Topic"
import CreateContentTopic from "../pages/Content/Topic/Create"
import UpdateContentTopic from "../pages/Content/Topic/Update"
import ContentMaterial from "../pages/Content/Material"
import CreateContentMaterial from "../pages/Content/Material/Create"
import UpdateContentMaterial from "../pages/Content/Material/Update"
import Dictionary from "../pages/Content/Dictionary"
import CreateDictionary from "../pages/Content/Dictionary/Create"
import UpdateDictionary from "../pages/Content/Dictionary/Update"
import PricingPlan from "../pages/PricingPlan"
import CreatePricingPlan from "../pages/PricingPlan/Create"
import UpdatePricingPlan from "../pages/PricingPlan/Update"
import Subscription from "../pages/Subscription"
import CreateSubscription from "../pages/Subscription/Create"

const authProtectedRoutes = () => {
  return [
    { path: "/dashboard", component: <Dashboard /> },
    { path: "/user/admin", component: <UserAdmin /> },
    { path: "/user/pelajar", component: <UserPelajar /> },
    { path: "/content/science", component: <ContentIlmu /> },
    { path: "/content/science/create", component: <CreateContentIlmu /> },
    { path: "/content/science/update/:id", component: <UpdateContentIlmu /> },
    { path: "/content/topic", component: <ContentTopic /> },
    { path: "/content/topic/create", component: <CreateContentTopic /> },
    { path: "/content/topic/update/:id", component: <UpdateContentTopic /> },
    { path: "/content/material", component: <ContentMaterial /> },
    { path: "/content/material/create", component: <CreateContentMaterial /> },
    {
      path: "/content/material/update/:id",
      component: <UpdateContentMaterial />,
    },
    { path: "/content/dictionary", component: <Dictionary /> },
    { path: "/content/dictionary/create", component: <CreateDictionary /> },
    {
      path: "/content/dictionary/update/:id",
      component: <UpdateDictionary />,
    },
    { path: "/pricing-plan", component: <PricingPlan /> },
    { path: "/pricing-plan/create", component: <CreatePricingPlan /> },
    {
      path: "/pricing-plan/update/:pricingPlanid",
      component: <UpdatePricingPlan />,
    },
    { path: "/subscription", component: <Subscription /> },
    { path: "/subscription/create", component: <CreateSubscription /> },
    {
      path: "/",
      exact: true,
      component: <Navigate to="/dashboard" />,
    },
  ]
}

const publicRoutes = () => {
  return [{ path: "/login", component: <Login /> }]
}

export { authProtectedRoutes, publicRoutes }
