import currencyLocale from "constants/currencyLocale"

export const calculateDiscount = (price, discount) => {
  price = Number(price)
  discount = Number(discount)
  const result = price - Math.round(price / 100) * discount
  return result
}

export const roundUpToDecimal = (number, decimalPlaces) => {
  const factor = Math.pow(10, decimalPlaces)
  return Math.ceil(number * factor) / factor
}

export const calculateDiscountedPrice = (
  price,
  discount,
  currencyCode = "IDR"
) => {
  // Mengonversi harga dan diskon ke angka
  const locale = currencyLocale[currencyCode]
  price = Number(price)
  discount = Number(discount)

  // Validasi input untuk memastikan angka yang valid
  if (isNaN(price) || isNaN(discount)) {
    return 0 // Mengembalikan 0 jika input tidak valid
  }

  // Menghitung jumlah diskon sebagai persentase dari harga
  const discountAmount = (price * discount) / 100

  // Menghitung total harga setelah diskon
  let result = price - discountAmount

  // Membulatkan ke atas hanya angka di belakang koma
  result = roundUpToDecimal(result, 2)

  return result
}
