import PropTypes from "prop-types"
import React, { useCallback, useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Input
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Table from "../../../components/Table"
import Modal from "../../../components/Modal"
import Pagination from "../../../components/Pagination"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { ApiGetStudent, ApiDeleteStudent } from "../../../api/student"

import { limitPagination } from "../../../constants/data"

import dayjs from "../../../lib/dayjs"

//i18n
import { withTranslation } from "react-i18next"

const Index = props => {
  //meta title
  document.title = "Pelajar | Economates"
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [dataStudent, setDataStudent] = useState([])
  const [studentId, setStudentId] = useState("")
  const [modal, setModal] = useState({
    isOpen: false,
    title: "",
    description: "",
  })

  const [valueSearch, setValueSearch] = useState("")
  const [totalDataStudent, setTotalDataStudent] = useState(0)
  const [pagination, setPagination] = useState({
    totalPage: 0,
    currentPage: 1,
    nextPage: 0,
    prevPage: 0,
  })

  const onChangeSearch = useCallback(e => {
    let { value } = e.target
    setValueSearch(value)
    setPagination({
      totalPage: 0,
      currentPage: 1,
      nextPage: 0,
      prevPage: 0,
    })
  }, [])

  const handleClickUpdate = useCallback(
    id => {
      navigate(`/user/pelajar/update/${id}`)
    },
    [navigate]
  )

  const handleClickDelete = useCallback(id => {
    setModal(oldState => ({
      ...oldState,
      isOpen: true,
      title: "Confirmation",
      description: "Apakah anda yakin ingin menghapus data ini ?",
    }))
    setStudentId(id)
  }, [])

  const handleGetData = useCallback(() => {
    ApiGetStudent(valueSearch, pagination.currentPage, limitPagination).then(
      response => {
        if (response) {
          const { status, result } = response

          if (status === 200) {
            const dataArr = []

            for (let i = 0; i < result.data.length; i++) {
              dataArr.push({
                id: result.data[i].student_id,
                name: result.data[i].name,
                email: result.data[i].email,
                totalPoint: `${result.data[i].point_amount} Poin`,
                active: {
                  type: "badge",
                  color:
                    result.data[i].is_active === "1" ? "success" : "danger",
                  text:
                    result.data[i].is_active === "1" ? "Aktif" : "Tidak Aktif",
                },
                premiumStatus: {
                  type: "badge",
                  color:
                    result.data[i].is_premium === "1" ? "success" : "danger",
                  text:
                    result.data[i].is_premium === "1" ? "Aktif" : "Tidak Aktif",
                },
                createdAt: dayjs(result.data[i].created_at).format(
                  "DD MMMM YYYY HH:mm:ss"
                ),
                updatedAt:
                  result.data[i].updated_at === null
                    ? "-"
                    : dayjs(result.data[i].updated_at).format(
                        "DD MMMM YYYY HH:mm:ss"
                      ),
                // actions: (
                //   <UncontrolledDropdown>
                //     <DropdownToggle href="#" className="card-drop" tag="i">
                //       <i
                //         style={{ cursor: "pointer" }}
                //         className="mdi mdi-dots-horizontal font-size-18"
                //       />
                //     </DropdownToggle>
                //     <DropdownMenu className="dropdown-menu-end">
                //       <DropdownItem
                //         onClick={() =>
                //           handleClickUpdate(result.data[i].student_id)
                //         }
                //       >
                //         <i className="mdi mdi-pencil text-secondary me-1" />{" "}
                //         <span className="text-secondary">Edit Data</span>
                //       </DropdownItem>
                //       <DropdownItem
                //         onClick={() =>
                //           handleClickDelete(result.data[i].student_id)
                //         }
                //       >
                //         <i className="mdi mdi-trash-can text-secondary me-1" />{" "}
                //         <span className="text-secondary">Hapus Data</span>
                //       </DropdownItem>
                //     </DropdownMenu>
                //   </UncontrolledDropdown>
                // ),
              })
            }

            if (!result.pagination.next && !result.pagination.prev) {
              setPagination(oldState => ({
                ...oldState,
                totalPage: result.totalPage,
              }))
            } else {
              if (result.pagination.next) {
                setPagination(oldState => ({
                  ...oldState,
                  nextPage: result.pagination.next.page,
                }))
              } else {
                setPagination(oldState => ({
                  ...oldState,
                  nextPage: 0,
                }))
              }

              if (result.pagination.prev) {
                setPagination(oldState => ({
                  ...oldState,
                  prevPage: result.pagination.prev.page,
                }))
              } else {
                setPagination(oldState => ({
                  ...oldState,
                  prevPage: 0,
                }))
              }

              setPagination(oldState => ({
                ...oldState,
                totalPage: result.totalPage,
              }))
            }

            setTotalDataStudent(result.totalData)
            setDataStudent(dataArr)
          }
        }

        setIsLoading(false)
      }
    )
  }, [handleClickUpdate, handleClickDelete, valueSearch, pagination])

  useEffect(() => {
    setIsLoading(true)
    let timeout = setTimeout(() => {
      handleGetData()
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [valueSearch, pagination.currentPage])

  const handleCloseModal = useCallback(() => {
    setModal(oldState => ({
      ...oldState,
      isOpen: false,
      title: "",
      description: "",
    }))
    setStudentId("")
  }, [])

  const handleSubmitDelete = useCallback(() => {
    ApiDeleteStudent(studentId).then(response => {
      if (response) {
        const { status, message } = response

        if (status === 200) {
          handleCloseModal()
          const state = [...dataTopic]
          const indexData = state.map(d => d.id).indexOf(studentId)
          state.splice(indexData, 1)
          const totalData = totalDataStudent - 1
          const page = Math.ceil(totalData / limitPagination)
          setTotalDataStudent(totalData)
          setPagination(oldState => ({
            ...oldState,
            currentPage: page,
          }))
          setDataStudent(state)
          toastr.success(message, "Success")
        } else if (status === 400) {
          handleCloseModal()
          toastr.error(message, "Error")
        }
      }
    })
  }, [studentId, handleCloseModal, dataStudent, totalDataStudent])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Pelajar")}
            breadcrumbItem={props.t("Pelajar")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <Row className="mb-4">
                    <Col md="12" sm="12" className="d-flex justify-content-end">
                      <Link
                        to="/content/pelajar/create"
                        className="btn btn-primary"
                      >
                        <i className="fas fa-plus"></i> Tambah Pelajar
                      </Link>
                    </Col>
                  </Row> */}

                  <Row className="mb-2">
                    <Col md="3">
                      <Input
                        value={valueSearch}
                        onChange={onChangeSearch}
                        placeholder="cari pelajar..."
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12">
                      <Table
                        column={[
                          "Nama Pelajar",
                          "Email",
                          "Total Poin",
                          "Status Akun",
                          "Status Premium",
                          "Tanggal Dibuat",
                          "Tanggal Diedit",
                          // "Aksi",
                        ]}
                        row={dataStudent}
                        isLoading={isLoading}
                      />
                    </Col>
                  </Row>

                  {!isLoading ? (
                    <Row className="mt-3">
                      <Pagination
                        totalPage={pagination.totalPage}
                        currentPage={pagination.currentPage}
                        nextPage={pagination.nextPage}
                        prevPage={pagination.prevPage}
                        setPagination={setPagination}
                      />
                    </Row>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modal.isOpen}
        title={modal.title}
        description={modal.description}
        onClose={handleCloseModal}
        tetxButtonLeft="Cancel"
        tetxButtonRight="Ya, Hapus"
        onSubmit={handleSubmitDelete}
      />
    </React.Fragment>
  )
}

Index.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Index)
