import PropTypes from "prop-types"
import React, { useState, useEffect, useCallback } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap"

import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { ApiGetScience } from "../../../api/science"
import { ApiAddTopic } from "../../../api/topic"

//i18n
import { withTranslation } from "react-i18next"

const CreateTopic = props => {
  //meta title
  document.title = "Tambah Topik | Economates"

  const [valueScience, setValueScience] = useState("")
  const [sourceScience, setSourceScience] = useState([])
  const [valueNameId, setValueNameId] = useState("")
  const [valueNameEn, setValueNameEn] = useState("")
  const [valueDescriptionId, setValueDescriptionId] = useState("")
  const [valueDescriptionEn, setValueDescriptionEn] = useState("")
  const [disabledButton, setDisabledButton] = useState(true)

  useEffect(() => {
    ApiGetScience().then(response => {
      if (response) {
        const { status, result } = response

        if (status === 200) {
          if (result.data.length > 0) {
            const dataArr = []
            for (let i = 0; i < result.data.length; i++) {
              dataArr.push({
                id: result.data[i].content_science_id,
                label: `${
                  result.data[i].name_id
                    ? result.data[i].name_id
                    : result.data[i].name
                }`,
              })
            }
            setSourceScience(dataArr)
          }
        }
      }
    })
  }, [])

  useEffect(() => {
    if (
      valueNameId === "" ||
      valueNameEn === "" ||
      valueScience === "" ||
      valueDescriptionId === "" ||
      valueDescriptionEn === ""
    ) {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
  }, [
    valueNameId,
    valueNameEn,
    valueScience,
    valueDescriptionId,
    valueDescriptionEn,
  ])

  const onChangeScience = useCallback(e => {
    let { value } = e.target
    setValueScience(value)
  }, [])

  const handleChangeNameId = useCallback(e => {
    const { value } = e.target
    setValueNameId(value)
  }, [])

  const handleChangeNameEn = useCallback(e => {
    const { value } = e.target
    setValueNameEn(value)
  }, [])

  const handleChangeDescriptionId = useCallback(e => {
    const { value } = e.target
    setValueDescriptionId(value)
  }, [])

  const handleChangeDescriptionEn = useCallback(e => {
    const { value } = e.target
    setValueDescriptionEn(value)
  }, [])

  const onSubmit = useCallback(() => {
    const payload = {
      scienceId: valueScience,
      name_id: valueNameId,
      name_en: valueNameEn,
      description_id: valueDescriptionId,
      description_en: valueDescriptionEn,
    }
    ApiAddTopic(payload).then(response => {
      if (response) {
        const { status, message } = response

        if (status == 201) {
          setValueScience("")
          setValueNameId("")
          setValueNameEn("")
          setValueDescriptionId("")
          setValueDescriptionEn("")
          toastr.success(message, "Success")
        }
      }
    })
  }, [
    valueNameId,
    valueNameEn,
    valueScience,
    valueDescriptionId,
    valueDescriptionEn,
  ])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Tambah")}
            breadcrumbItem={props.t("Topik")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="mx-auto col-10">
                      <Form>
                        <div className="mb-3 ">
                          <Label>
                            Bidang Ilmu <span className="text-danger">*</span>
                          </Label>
                          <select
                            value={valueScience}
                            className="form-select"
                            onChange={onChangeScience}
                          >
                            <option value="">Pilih Bidang Ilmu</option>
                            {sourceScience.map((d, i) => (
                              <option value={d.id} key={i}>
                                {d.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <Row>
                          <Col md="6" className="mb-3">
                            <Label>
                              Nama Topik ID{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={valueNameId}
                              type="text"
                              className="form-control"
                              onChange={handleChangeNameId}
                              placeholder="masukkan nama topik indonesia"
                            />
                          </Col>

                          <Col md="6" className="mb-3">
                            <Label>
                              Nama Topik EN{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={valueNameEn}
                              type="text"
                              className="form-control"
                              onChange={handleChangeNameEn}
                              placeholder="masukkan nama topik inggris"
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col md="6" className="mb-3 ">
                            <Label>
                              Description ID{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={valueDescriptionId}
                              type="textarea"
                              className="form-control"
                              onChange={handleChangeDescriptionId}
                              placeholder="masukkan deskripsi indonesia"
                            />
                          </Col>

                          <Col md="6" className="mb-3 ">
                            <Label>
                              Description EN{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={valueDescriptionEn}
                              type="textarea"
                              className="form-control"
                              onChange={handleChangeDescriptionEn}
                              placeholder="masukkan deskripsi inggris"
                            />
                          </Col>
                        </Row>
                      </Form>
                    </Col>

                    <Col className="mx-auto col-10">
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/content/topic"
                          className="btn btn-danger mb-2 me-2"
                        >
                          <i className="fas fa-arrow-left"></i> Kembali
                        </Link>
                        <Button
                          type="button"
                          color="primary"
                          className="mb-2"
                          onClick={onSubmit}
                          disabled={disabledButton}
                        >
                          <i className="fas fa-save"></i> Simpan
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CreateTopic.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(CreateTopic)
