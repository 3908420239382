import React, { useCallback, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Spinner,
  Input,
} from "reactstrap"
import Select from "react-select"
import { Link, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Modal from "../../components/Modal"
import Pagination from "../../components/Pagination"
// import Input from "../../components/Input"

import {
  ApiGetSubscription,
  ApiGenerateInvoiceSubscription,
} from "../../api/subscription"

import { ApiGetStudent } from "../../api/student"

import { limitPagination } from "../../constants/data"

import { ReplaceTwoNumberZeroAfterPoint } from "utils/replace"

import { baseUrl } from "../../config/env"
import dayjs from "../../lib/dayjs"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

const Index = props => {
  //meta title
  document.title = "Berlangganan | Economates"

  const [dataSubsribe, setDataSubscribe] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [pagination, setPagination] = useState({
    totalPage: 0,
    currentPage: 1,
    nextPage: 0,
    prevPage: 0,
  })
  const [dataStudent, setDataStudent] = useState([])
  const [valueStudent, setValueStudent] = useState("")
  const [valueActive, setValueActive] = useState("")
  const [valueCode, setValueCode] = useState("")

  useEffect(() => {
    ApiGetStudent().then(response => {
      if (response) {
        const { status, result } = response

        if (status === 200) {
          if (result.data.length > 0) {
            const dataArr = []
            for (let i = 0; i < result.data.length; i++) {
              dataArr.push({
                value: result.data[i].student_id,
                label: result.data[i].email,
              })
            }
            setDataStudent(dataArr)
          }
        }
      }
    })
  }, [])

  const handleDownloadInvoice = useCallback(
    (id, path) => {
      if (path) {
        const url = `${baseUrl}/${path}`
        window.open(url)
        // fetch(url)
        //   .then(response => response.blob())
        //   .then(blob => {
        //     const blobURL = window.URL.createObjectURL(new Blob([blob]))
        //     const fileName = url.split("/").pop()
        //     const aTag = document.createElement("a")
        //     aTag.href = blobURL
        //     aTag.download = fileName
        //     // aTag.setAttribute("download", fileName)
        //     document.body.appendChild(aTag)
        //     aTag.click()
        //     document.body.removeChild(aTag)
        //     // aTag.remove()
        //   })
      } else {
        const payload = {
          subscriptionId: id,
        }
        ApiGenerateInvoiceSubscription(payload).then(response => {
          if (response) {
            const { status, result } = response
            if (status === 201) {
              const url = `${baseUrl}/${result.path}`
              const state = [...dataSubsribe]
              const index = dataSubsribe
                .map(d => d.subscriptions_id)
                .indexOf(id)
              state[index].path_invoice = result.path
              setDataSubscribe(state)
              handleDownloadInvoice(id, result.path)
            }
          }
        })
      }
    },
    [dataSubsribe]
  )

  const handleGetData = useCallback(() => {
    ApiGetSubscription(
      pagination.currentPage,
      limitPagination,
      valueStudent.value,
      valueActive,
      valueCode
    ).then(response => {
      if (response) {
        const { status, result } = response
        if (status === 200) {
          if (!result.pagination.next && !result.pagination.prev) {
            setPagination(oldState => ({
              ...oldState,
              totalPage: result.totalPage,
            }))
          } else {
            if (result.pagination.next) {
              setPagination(oldState => ({
                ...oldState,
                nextPage: result.pagination.next.page,
              }))
            } else {
              setPagination(oldState => ({
                ...oldState,
                nextPage: 0,
              }))
            }

            if (result.pagination.prev) {
              setPagination(oldState => ({
                ...oldState,
                prevPage: result.pagination.prev.page,
              }))
            } else {
              setPagination(oldState => ({
                ...oldState,
                prevPage: 0,
              }))
            }

            setPagination(oldState => ({
              ...oldState,
              totalPage: result.totalPage,
            }))
          }

          setDataSubscribe(result.data)
        }

        setIsLoading(false)
      }
    })
  }, [pagination, valueStudent, valueActive, valueCode])

  useEffect(() => {
    setIsLoading(true)
    let timeout = setTimeout(() => {
      handleGetData()
    }, 600)

    return () => {
      clearTimeout(timeout)
    }
  }, [pagination.currentPage, valueStudent, valueActive, valueCode])

  const handleChangeStudent = useCallback(value => {
    setValueStudent(value)
  }, [])

  const handleChangeActive = useCallback(e => {
    const { value } = e.target
    setValueActive(value)
  }, [])

  const handleChangeCode = useCallback(e => {
    const { value } = e.target
    setValueCode(value)
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Berlangganan"} breadcrumbItem={"Berlangganan"} />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-4">
                    <Col md="12" sm="12" className="d-flex justify-content-end">
                      <Link
                        to="/subscription/create"
                        className="btn btn-primary"
                      >
                        <i className="fas fa-plus"></i> Tambah Berlangganan
                      </Link>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col md="3">
                      <Select
                        value={valueStudent}
                        onChange={handleChangeStudent}
                        options={dataStudent}
                        className="select2-selection"
                      />
                    </Col>

                    <Col md="3">
                      <select
                        value={valueActive}
                        className="form-select"
                        onChange={handleChangeActive}
                      >
                        <option value="">Status Berlangganan</option>
                        <option value="0">Tidak Aktif</option>
                        <option value="1">Aktif</option>
                      </select>
                    </Col>

                    <Col md="3">
                      <Input
                        value={valueCode}
                        onChange={handleChangeCode}
                        placeholder="cari kode berlangganan..."
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <Table className="text-center align-middle table-nowrap">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Kode Berlangganan</th>
                              <th>Nama Pelajar</th>
                              <th>Judul Paket ID</th>
                              <th>Judul Paket EN</th>
                              <th>Mata Uang</th>
                              <th>Total Harga</th>
                              <th>Channel</th>
                              <th>Waktu Mulai</th>
                              <th>Waktu Berakhir</th>
                              <th>Status Pembayaran</th>
                              <th>Status Berlangganan</th>
                              <th>Aksi</th>
                            </tr>
                          </thead>

                          {!isLoading &&
                            dataSubsribe.length > 0 &&
                            dataSubsribe.map((d, i) => {
                              return (
                                <tbody key={i}>
                                  <tr>
                                    <th>{i + 1}</th>
                                    <td>{d.code}</td>
                                    <td>{d.student.name}</td>
                                    <td>{d.title_package_id}</td>
                                    <td>{d.title_package_en || "-"}</td>
                                    <td>{d.currency}</td>
                                    <td>
                                      {ReplaceTwoNumberZeroAfterPoint(d.price)}
                                    </td>
                                    <td>
                                      <span
                                        className={`font-size-12 py-2 px-3 badge-soft-${
                                          d.channel === "dashboard"
                                            ? "info"
                                            : "warning"
                                        } badge bg-secondary`}
                                      >
                                        {d.channel}
                                      </span>
                                    </td>
                                    <td>
                                      {dayjs(d.start_time).format(
                                        "DD MMMM YYYY HH:mm:ss"
                                      )}
                                    </td>
                                    <td>
                                      {dayjs(d.end_time).format(
                                        "DD MMMM YYYY HH:mm:ss"
                                      )}
                                    </td>
                                    <td>
                                      <span
                                        className={`font-size-12 py-2 px-3 badge-soft-${
                                          d.payment_status === "1"
                                            ? "success"
                                            : "danger"
                                        } badge bg-secondary`}
                                      >
                                        {d.payment_status === "1"
                                          ? "Lunas"
                                          : "Belum Lunas"}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={`font-size-12 py-2 px-3 badge-soft-${
                                          d.is_active === "1"
                                            ? "success"
                                            : "danger"
                                        } badge bg-secondary`}
                                      >
                                        {d.is_active === "1"
                                          ? "Aktif"
                                          : "Tidak Aktif"}
                                      </span>
                                    </td>

                                    <td>
                                      <div className="d-flex gap-3 justify-content-center">
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            href="#"
                                            className="card-drop"
                                            tag="i"
                                          >
                                            <i
                                              style={{ cursor: "pointer" }}
                                              className="mdi mdi-dots-horizontal font-size-18"
                                            />
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem
                                              onClick={() =>
                                                handleDownloadInvoice(
                                                  d.subscriptions_id,
                                                  d.path_invoice
                                                )
                                              }
                                            >
                                              <i className="mdi mdi-download text-secondary me-1" />{" "}
                                              <span className="text-secondary">
                                                Download Invoice
                                              </span>
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            })}
                        </Table>

                        {!isLoading && !dataSubsribe.length > 0 && (
                          <div className="d-flex justify-content-center pt-5 pb-5 text-danger">
                            Data Not Found
                          </div>
                        )}

                        {isLoading && (
                          <div className="d-flex justify-content-center pt-5 pb-5 text-primary">
                            <Spinner />
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>

                  {!isLoading ? (
                    <Row className="mt-3">
                      <Pagination
                        totalPage={pagination.totalPage}
                        currentPage={pagination.currentPage}
                        nextPage={pagination.nextPage}
                        prevPage={pagination.prevPage}
                        setPagination={setPagination}
                      />
                    </Row>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
