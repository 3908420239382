import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Table from "../../../components/Table"
import Modal from "../../../components/Modal"
import Switch from "../../../components/Switch"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import dayjs from "../../../lib/dayjs"

//i18n
import { withTranslation } from "react-i18next"

import {
  ApiGetScience,
  ApiDeleteScience,
  ApiStatusLeatestScience,
} from "../../../api/science"

const Index = props => {
  //meta title
  document.title = "Ilmu | Economates"
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [dataScience, setDataScience] = useState([])
  const [scienceId, setScienceId] = useState([])
  const [modal, setModal] = useState({
    isOpen: false,
    title: "",
    description: "",
  })

  const [scienceIndex, setScienceIndex] = useState("")
  const [scienceStatusLeatest, setScienceStatusLeatest] = useState("")

  const handleClickUpdate = useCallback(
    id => {
      navigate(`/content/science/update/${id}`)
    },
    [navigate]
  )

  const handleClickDelete = useCallback(id => {
    setModal(oldState => ({
      ...oldState,
      isOpen: true,
      title: "Confirmation",
      description: "Apakah anda yakin ingin menghapus data ini ?",
    }))
    setScienceId(id)
  }, [])

  const handleToogleStatusLeatest = useCallback((id, value, index) => {
    const payload = {
      status: value === "0" ? "1" : "0",
    }
    ApiStatusLeatestScience(id, payload).then(response => {
      if (response) {
        const { status } = response
        if (status === 200) {
          setScienceId(id)
          setScienceStatusLeatest(payload.status)
          setScienceIndex(index)
        }
      }
    })
  }, [])

  const handleGetData = useCallback(() => {
    setIsLoading(true)
    ApiGetScience().then(response => {
      if (response) {
        const { status, result } = response

        if (status === 200) {
          const dataArr = []

          for (let i = 0; i < result.data.length; i++) {
            dataArr.push({
              name_id: result.data[i].name_id
                ? result.data[i].name_id
                : result.data[i].name,
              name_en: result.data[i].name_en ? result.data[i].name_en : "-",
              statusLeatest: (
                <Switch
                  isChecked={result.data[i].is_new === "0" ? false : true}
                  onChange={() =>
                    handleToogleStatusLeatest(
                      result.data[i].content_science_id,
                      result.data[i].is_new,
                      i
                    )
                  }
                />
              ),
              createdAt: dayjs(result.data[i].created_at).format(
                "DD MMMM YYYY HH:mm:ss"
              ),
              updatedAt:
                result.data[i].updated_at === null
                  ? "-"
                  : dayjs(result.data[i].updated_at).format(
                      "DD MMMM YYYY HH:mm:ss"
                    ),
              createdBy: result.data[i].created_by.name,
              updatedBy:
                result.data[i].updated_by.name === null
                  ? "-"
                  : result.data[i].updated_by.name,
              actions: (
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="card-drop" tag="i">
                    <i
                      style={{ cursor: "pointer" }}
                      className="mdi mdi-dots-horizontal font-size-18"
                    />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={() =>
                        handleClickUpdate(result.data[i].content_science_id)
                      }
                    >
                      <i className="mdi mdi-pencil text-secondary me-1" />{" "}
                      <span className="text-secondary">Edit Data</span>
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        handleClickDelete(result.data[i].content_science_id)
                      }
                    >
                      <i className="mdi mdi-trash-can text-secondary me-1" />{" "}
                      <span className="text-secondary">Hapus Data</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ),
            })
          }
          setDataScience(dataArr)
        }
      }

      setIsLoading(false)
    })
  }, [handleClickUpdate, handleClickDelete, handleToogleStatusLeatest])

  useEffect(() => {
    if (scienceStatusLeatest) {
      const state = [...dataScience]
      state[scienceIndex].statusLeatest = (
        <Switch
          isChecked={scienceStatusLeatest === "1" ? true : false}
          onChange={() =>
            handleToogleStatusLeatest(
              scienceId,
              scienceStatusLeatest,
              scienceIndex
            )
          }
        />
      )
      setDataScience(state)
      setScienceIndex("")
      setScienceId("")
      setScienceStatusLeatest("")
    }
  }, [
    dataScience,
    scienceStatusLeatest,
    scienceId,
    scienceIndex,
    handleToogleStatusLeatest,
  ])

  useEffect(() => {
    handleGetData()
  }, [])

  const handleCloseModal = useCallback(() => {
    setModal(oldState => ({
      ...oldState,
      isOpen: false,
      title: "",
      description: "",
    }))
    setScienceId("")
  }, [])

  const handleSubmitDelete = useCallback(() => {
    ApiDeleteScience(scienceId).then(response => {
      if (response) {
        const { status, message } = response

        if (status === 200) {
          handleCloseModal()
          handleGetData()
          toastr.success(message, "Success")
        } else if (status === 400) {
          handleCloseModal()
          toastr.error(message, "Error")
        }
      }
    })
  }, [scienceId, handleCloseModal, handleGetData])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Ilmu")}
            breadcrumbItem={props.t("Ilmu")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-4">
                    <Col md="12" sm="12" className="d-flex justify-content-end">
                      <Link
                        to="/content/science/create"
                        className="btn btn-primary"
                      >
                        <i className="fas fa-plus"></i> Tambah Ilmu
                      </Link>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12">
                      <Table
                        column={[
                          "Nama Ilmu ID",
                          "Nama Ilmu EN",
                          "Status Terbaru",
                          "Tanggal Dibuat",
                          "Tanggal Diedit",
                          "Dibuat Oleh",
                          "Diedit Oleh",
                          "Aksi",
                        ]}
                        row={dataScience}
                        isLoading={isLoading}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modal.isOpen}
        title={modal.title}
        description={modal.description}
        onClose={handleCloseModal}
        tetxButtonLeft="Cancel"
        tetxButtonRight="Ya, Hapus"
        onSubmit={handleSubmitDelete}
      />
    </React.Fragment>
  )
}

Index.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Index)
