import PropTypes from "prop-types"
import React, { useState, useCallback, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  Form,
  Input,
  Button,
  InputGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import Select from "react-select"

import { Link } from "react-router-dom"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { ApiGetPricingPlan } from "../../api/pricingPlan"
import { ApiAddSubscription } from "../../api/subscription"
import { ApiGetStudent } from "../../api/student"
import { ReplaceToRupiah } from "../../utils/replace"
import currencyCode from "constants/currencyCode"
import { calculateDiscountedPrice } from "utils/calculate"

const CreateTopic = props => {
  //meta title
  document.title = "Tambah Berlangganan | Economates"

  const [dataUser, setDataUser] = useState([])
  const [sourceUser, setSourceUser] = useState([])
  const [detailUser, setDetailUser] = useState({})
  const [valueUser, setValueUser] = useState(null)
  const [dataPricingPlan, setDataPricingPlan] = useState([])
  const [sourcePricingPlan, setSourcePricingPlan] = useState([])
  const [valuePricingPlan, setValuePricingPlan] = useState("")
  const [detailPrice, setDetailPrice] = useState({})
  const [disabledButton, setDisabledButton] = useState(true)
  const [isOpenCurrency, setIsOpenCurrency] = useState(false)
  const [valueCurrency, setValueCurrency] = useState("IDR")

  const toggleOpenCurrency = () => {
    setIsOpenCurrency(!isOpenCurrency)
  }

  useEffect(() => {
    ApiGetPricingPlan("1").then(response => {
      if (response) {
        const { status, result } = response

        if (status === 200) {
          if (result.data.length > 0) {
            const dataArr = []
            for (let i = 0; i < result.data.length; i++) {
              dataArr.push({
                value: result.data[i].pricing_plan_id,
                label: result.data[i].title_id
                  ? result.data[i].title_id
                  : result.data[i].title,
              })
            }
            setDataPricingPlan(dataArr)
            setSourcePricingPlan(result.data)
          }
        }
      }
    })
  }, [])

  useEffect(() => {
    ApiGetStudent().then(response => {
      if (response) {
        const { status, result } = response

        if (status === 200) {
          if (result.data.length > 0) {
            const dataArr = []
            for (let i = 0; i < result.data.length; i++) {
              dataArr.push({
                value: result.data[i].student_id,
                label: result.data[i].email,
              })
            }
            setDataUser(dataArr)
            setSourceUser(result.data)
          }
        }
      }
    })
  }, [])

  useEffect(() => {
    if (
      valueUser === null ||
      valuePricingPlan === "" ||
      detailPrice.original_price === undefined ||
      detailPrice.original_price === ""
    ) {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
  }, [valueUser, valuePricingPlan, detailPrice])

  const handleChangeUser = useCallback(
    value => {
      const findUser = sourceUser.find(d => d.student_id === value.value)
      setDetailUser(findUser)
      setValueUser(value)
    },
    [sourceUser]
  )

  const handleChangePricingPlan = useCallback(
    e => {
      const { value } = e.target

      if (value) {
        let findData = sourcePricingPlan.find(
          d => d.pricing_plan_id === Number(value)
        )

        findData.original_price = findData.original_price_id
          ? findData.original_price_id
          : findData.original_price

        findData.discount = findData.discount_id
          ? findData.discount_id
          : findData.discount

        findData.total_price = findData.total_price_id
          ? findData.total_price_id
          : findData.total_price

        setDetailPrice(findData)
      } else {
        setDetailPrice({})
      }
      setValuePricingPlan(value)
    },
    [sourcePricingPlan]
  )

  const handleChangeOriginalPrice = useCallback(
    e => {
      let { value } = e.target

      if (value === "") {
        setDetailPrice(oldState => ({
          ...oldState,
          original_price: value,
          total_price: "0",
        }))
        return true
      }

      value = value.replace(/[^0-9.]/g, "")
      setDetailPrice(oldState => ({
        ...oldState,
        original_price: value,
      }))

      const totalHarga = calculateDiscountedPrice(
        value,
        detailPrice.discount,
        valueCurrency
      )
      setDetailPrice(oldState => ({
        ...oldState,
        total_price: totalHarga,
      }))
    },
    [detailPrice, valueCurrency]
  )

  const onSubmit = useCallback(() => {
    const payload = {
      studentId: valueUser.value,
      pricingPlanId: Number(valuePricingPlan),
      currency: valueCurrency,
      totalPrice: Number(detailPrice.total_price),
    }
    ApiAddSubscription(payload).then(response => {
      if (response) {
        const { status, message } = response
        if (status == 201) {
          setValueUser(null)
          setValuePricingPlan("")
          setDetailUser({})
          setDetailPrice({})
          setValueCurrency("IDR")
          toastr.success(message, "Success")
        } else {
          toastr.warning(message, "Warning")
        }
      }
    })
  }, [valueUser, valuePricingPlan, valueCurrency, detailPrice])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Tambah")}
            breadcrumbItem={props.t("Berlangganan")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="mx-auto col-10">
                      <Form>
                        <Col>
                          <h5 className="fw-semibold mb-3">
                            Informasi Pelajar
                          </h5>

                          <div className="p-4 border-light border rounded mb-4">
                            <div className="mb-3 ">
                              <Label>Email Pelajar</Label>
                              <Select
                                value={valueUser}
                                onChange={handleChangeUser}
                                options={dataUser}
                                className="select2-selection"
                              />
                            </div>

                            <Row>
                              <Col xl="2" sm="4">
                                <div>
                                  <h6 className="fs-14 mb-2">Nama Pelajar :</h6>
                                  <p className="text-muted fs-14 mb-0">
                                    {detailUser.name
                                      ? `${detailUser.name}`
                                      : "-"}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col>
                          <h5 className="fw-semibold mb-3">Informasi Harga</h5>

                          <div className="p-4 border-light border rounded mb-4">
                            <div className="mb-3 ">
                              <Label>Paket</Label>
                              <select
                                value={valuePricingPlan}
                                className="form-select"
                                onChange={handleChangePricingPlan}
                              >
                                <option value="">Pilih Paket</option>
                                {dataPricingPlan.map((d, i) => (
                                  <option value={d.value} key={i}>
                                    {d.label}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <Row className="mb-3">
                              <Col xl="3" sm="4">
                                <div>
                                  <h6 className="fs-14 mb-2">Durasi :</h6>
                                  <p className="text-muted fs-14 mb-0">
                                    {detailPrice.duration
                                      ? `${detailPrice.duration} Hari`
                                      : "-"}
                                  </p>
                                </div>
                              </Col>

                              <Col xl="3" sm="4">
                                <div>
                                  <h6 className="fs-14 mb-2">Harga Asli :</h6>

                                  <p className="text-muted fs-14 mb-0">
                                    {valuePricingPlan ? (
                                      <InputGroup>
                                        <ButtonDropdown
                                          addonType="append"
                                          isOpen={isOpenCurrency}
                                          toggle={toggleOpenCurrency}
                                          style={{ background: "red" }}
                                        >
                                          <DropdownToggle
                                            outline
                                            split
                                            style={{
                                              width: 60,
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>{valueCurrency}</div>

                                            <div>
                                              <i className="fas fa-angle-down" />
                                            </div>
                                          </DropdownToggle>
                                          <DropdownMenu
                                            style={{
                                              maxHeight: "150px",
                                              overflowY: "auto",
                                            }}
                                          >
                                            {[
                                              ...new Set(
                                                Object.values(currencyCode)
                                              ),
                                            ].map((d, i) => {
                                              return (
                                                <DropdownItem
                                                  key={i}
                                                  onClick={() => {
                                                    setValueCurrency(d)
                                                    setDetailPrice(
                                                      oldState => ({
                                                        ...oldState,
                                                        original_price: "",
                                                        total_price: "0",
                                                      })
                                                    )
                                                  }}
                                                >
                                                  {d}
                                                </DropdownItem>
                                              )
                                            })}
                                          </DropdownMenu>
                                        </ButtonDropdown>

                                        <Input
                                          value={detailPrice.original_price}
                                          placeholder="harga asli"
                                          onChange={handleChangeOriginalPrice}
                                        />
                                      </InputGroup>
                                    ) : (
                                      "-"
                                    )}
                                  </p>
                                </div>
                              </Col>

                              <Col xl="3" sm="4">
                                <div>
                                  <h6 className="fs-14 mb-2">Diskon :</h6>
                                  <p className="text-muted fs-14 mb-0">
                                    {valuePricingPlan
                                      ? `${
                                          detailPrice.discount_id !== null
                                            ? detailPrice.discount_id
                                            : detailPrice.discount
                                        }%`
                                      : "-"}
                                  </p>
                                </div>
                              </Col>

                              <Col xl="3" sm="4">
                                <div>
                                  <h6 className="fs-14 mb-2">Total Harga :</h6>
                                  <p className="text-muted fs-14 mb-0">
                                    {valuePricingPlan
                                      ? `${valueCurrency} ${ReplaceToRupiah(
                                          detailPrice.total_price
                                        )}`
                                      : "-"}
                                  </p>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="3" sm="4">
                                <div>
                                  <h6 className="fs-14 mb-2">Benefit :</h6>
                                  {detailPrice.listBenefit ? (
                                    <ul className="vstack gap-3">
                                      {detailPrice.listBenefit.map((d, i) => {
                                        return (
                                          <li key={i}>
                                            {d.description_id !== null
                                              ? d.description_id
                                              : d.description}
                                          </li>
                                        )
                                      })}
                                    </ul>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Form>
                    </Col>

                    <Col className="mx-auto col-10">
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/subscription"
                          className="btn btn-danger mb-2 me-2"
                        >
                          <i className="fas fa-arrow-left"></i> Kembali
                        </Link>
                        <Button
                          type="button"
                          color="primary"
                          className="mb-2"
                          onClick={onSubmit}
                          disabled={disabledButton}
                        >
                          <i className="fas fa-save"></i> Simpan
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CreateTopic.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(CreateTopic)
