import React, { memo, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import "./style.css"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ url }) => {
  const [numPages, setNumPages] = useState()
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <div className="pdf-div">
      <p>
        Page {pageNumber} of {numPages}
      </p>

      <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.apply(null, Array(numPages))
          .map((_, i) => i + 1)
          .map((page, i) => {
            return (
              <Page
                key={i}
                pageNumber={page}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            )
          })}
      </Document>
    </div>
  )
}

export default memo(PdfViewer)
