import PropTypes from "prop-types"
import React, { useState, useCallback, useEffect, useMemo } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  Form,
  Input,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap"

import { Link } from "react-router-dom"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ReplaceToRupiah, ReplaceDot } from "../../utils/replace"
import { calculateDiscount } from "../../utils/calculate"
import { RegexNumberNotAllowSpace } from "../../utils/regex"

//i18n
import { withTranslation } from "react-i18next"

import { ApiAddPricingPlan } from "../../api/pricingPlan"

const CreateTopic = props => {
  //meta title
  document.title = "Tambah Ilmu | Economates"

  const [valueGoogleProductId, setValueGoogleProductId] = useState("")
  const [valueTitleId, setValueTitleId] = useState("")
  const [valueTitleEn, setValueTitleEn] = useState("")
  const [valueDuration, setValueDuration] = useState("0")
  const [valueOriginalPriceId, setValueOriginalPriceId] = useState("0")
  const [valueDiscountId, setValueDiscountId] = useState("0")
  const [valueRecommended, setValueRecommended] = useState("")

  const [valueOriginalPriceEn, setValueOriginalPriceEn] = useState("0")
  const [valueDiscountEn, setValueDiscountEn] = useState("0")

  const [listBenefit, setListBenefit] = useState([
    {
      description_id: "",
      description_en: "",
    },
  ])
  const [disabledButton, setDisabledButton] = useState(true)

  // access type
  const [valueAccessType, setValueAccessType] = useState("")

  useEffect(() => {
    const filterListbenefitId = listBenefit.filter(d => d.description_id === "")
    const filterListbenefitEn = listBenefit.filter(d => d.description_en === "")
    if (
      valueGoogleProductId === "" ||
      valueTitleId === "" ||
      valueTitleEn === "" ||
      valueDuration === "" ||
      valueDuration === "0" ||
      valueOriginalPriceId === "" ||
      valueOriginalPriceId === "0" ||
      valueOriginalPriceEn === "" ||
      valueOriginalPriceEn === "0" ||
      valueDiscountId === "" ||
      valueDiscountEn === "" ||
      valueRecommended === "" ||
      valueAccessType === "" ||
      filterListbenefitId.length > 0 ||
      filterListbenefitEn.length > 0
    ) {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
  }, [
    valueGoogleProductId,
    valueTitleId,
    valueTitleEn,
    valueDuration,
    valueOriginalPriceId,
    valueOriginalPriceEn,
    valueDiscountId,
    valueDiscountId,
    valueRecommended,
    valueAccessType,
    listBenefit,
  ])

  const handleChangeGoogleProductId = useCallback(e => {
    const { value } = e.target
    setValueGoogleProductId(value)
  }, [])

  const handleChangeNameId = useCallback(e => {
    const { value } = e.target
    setValueTitleId(value)
  }, [])

  const handleChangeNameEn = useCallback(e => {
    const { value } = e.target
    setValueTitleEn(value)
  }, [])

  const handleChangeDuration = useCallback(e => {
    const { value } = e.target

    if (value === "") {
      setValueDuration(value)
      return true
    }

    if (RegexNumberNotAllowSpace.test(value)) {
      setValueDuration(value)
      return true
    }
  }, [])

  const handleChangeOriginalPriceId = useCallback(e => {
    let { value } = e.target

    if (value === "") {
      setValueOriginalPriceId(value)
      return true
    }

    value = ReplaceDot(value)
    if (RegexNumberNotAllowSpace.test(value)) {
      value = ReplaceToRupiah(value)
      setValueOriginalPriceId(value)
    }
  }, [])

  const handleChangeOriginalPriceEn = useCallback(e => {
    let inputValue = e.target.value

    // Remove all characters except digits and decimal point
    inputValue = inputValue.replace(/[^0-9.]/g, "")

    // Remove all but the first decimal point
    inputValue = inputValue.replace(/(\..*?)\..*/g, "$1")

    // Add commas for thousands
    inputValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    setValueOriginalPriceEn(inputValue)
  }, [])

  const handleChangeDiscountId = useCallback(e => {
    const { value } = e.target

    if (value === "") {
      setValueDiscountId(value)
      return true
    }

    if (RegexNumberNotAllowSpace.test(value)) {
      setValueDiscountId(value)
      return true
    }
  }, [])

  const handleChangeDiscountEn = useCallback(e => {
    const { value } = e.target

    if (value === "") {
      setValueDiscountEn(value)
      return true
    }

    if (RegexNumberNotAllowSpace.test(value)) {
      setValueDiscountEn(value)
      return true
    }
  }, [])

  const onChangeRecommended = useCallback(e => {
    const { value } = e.target
    setValueRecommended(value)
  }, [])

  const onChangeAccessType = useCallback(e => {
    let { value } = e.target
    setValueAccessType(value)
  }, [])

  const handleAddColumnBenefit = useCallback(() => {
    const payload = {
      description_id: "",
      description_en: "",
    }

    setListBenefit(oldState => [...oldState, payload])
  }, [])

  const handleRemoveColumnBenefit = useCallback(
    index => {
      const state = [...listBenefit]
      state.splice(index, 1)

      setListBenefit(state)
    },
    [listBenefit]
  )

  const handleChangeBenefitId = useCallback(
    (e, index) => {
      const { value } = e.target
      const state = [...listBenefit]
      state[index].description_id = value

      setListBenefit(state)
    },
    [listBenefit]
  )

  const handleChangeBenefitEn = useCallback(
    (e, index) => {
      const { value } = e.target
      const state = [...listBenefit]
      state[index].description_en = value

      setListBenefit(state)
    },
    [listBenefit]
  )

  const onResetForm = useCallback(() => {
    setValueGoogleProductId("")
    setValueTitleId("")
    setValueTitleEn("")
    setValueDuration("0")
    setValueOriginalPriceId("0")
    setValueOriginalPriceEn("0")
    setValueDiscountId("0")
    setValueDiscountEn("0")
    setValueRecommended("")
    setValueAccessType("")
    setListBenefit([
      {
        description_id: "",
        description_en: "",
      },
    ])
  }, [])

  const onSubmit = useCallback(() => {
    const dataArr = []
    for (let i = 0; i < listBenefit.length; i++) {
      dataArr.push({
        description_id: listBenefit[i].description_id,
        description_en: listBenefit[i].description_en,
      })
    }
    const payload = {
      googlePlayProductId: valueGoogleProductId,
      titleId: valueTitleId,
      titleEn: valueTitleEn,
      duration: Number(valueDuration),
      discountId: Number(valueDiscountId),
      discountEn: Number(valueDiscountEn),
      originalPriceId: Number(ReplaceDot(valueOriginalPriceId)),
      originalPriceEn: Number(ReplaceDot(valueOriginalPriceEn)),
      isRecommended: valueRecommended,
      accessType: valueAccessType,
      listBenefit: dataArr,
    }

    ApiAddPricingPlan(payload).then(response => {
      if (response) {
        const { status, message } = response

        if (status == 201) {
          onResetForm()
          toastr.success(message, "Success")
        }
      }
    })
  }, [
    valueGoogleProductId,
    valueTitleId,
    valueTitleEn,
    valueDuration,
    valueOriginalPriceId,
    valueOriginalPriceEn,
    valueDiscountId,
    valueDiscountEn,
    valueRecommended,
    valueAccessType,
    listBenefit,
    onResetForm,
  ])

  const totalPriceId = useMemo(() => {
    const originalPrice = ReplaceDot(valueOriginalPriceId)
    if (
      valueDiscountId !== "" &&
      valueOriginalPriceId !== "" &&
      valueOriginalPriceId !== "0" &&
      originalPrice !== false
    ) {
      return ReplaceToRupiah(calculateDiscount(originalPrice, valueDiscountId))
    } else {
      return "0"
    }
  }, [valueDiscountId, valueOriginalPriceId])

  const totalPriceEn = useMemo(() => {
    const numericPrice =
      parseFloat(valueOriginalPriceEn.replace(/[$,]/g, "")) || 0
    const numericDiscount = parseFloat(valueDiscountEn) || 0

    // Calculate the final price after discount
    const final = numericPrice - (numericPrice * numericDiscount) / 100

    // Format the final price
    return final
  }, [valueDiscountEn, valueOriginalPriceEn])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Tambah")}
            breadcrumbItem={props.t("Rencana Harga")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="mx-auto col-10">
                      <Form>
                        <Row className="mb-3">
                          <Col md="12">
                            <Label>
                              Google Product Id{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={valueGoogleProductId}
                              type="text"
                              className="form-control"
                              onChange={handleChangeGoogleProductId}
                              placeholder="masukkan google product id"
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <Label>
                              Judul ID <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={valueTitleId}
                              type="text"
                              className="form-control"
                              onChange={handleChangeNameId}
                              placeholder="masukkan judul"
                            />
                          </Col>

                          <Col md="6">
                            <Label>
                              Judul EN <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={valueTitleEn}
                              type="text"
                              className="form-control"
                              onChange={handleChangeNameEn}
                              placeholder="masukkan judul"
                            />
                          </Col>
                        </Row>

                        <Col className="mb-3 ">
                          <Label className="mb-2">Harga ID</Label>

                          <Card>
                            <CardBody>
                              <Row>
                                <Col md={5}>
                                  <Label>
                                    Harga Asli ID{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <InputGroup>
                                    <InputGroupText>Rp</InputGroupText>

                                    <Input
                                      value={valueOriginalPriceId}
                                      type="text"
                                      className="form-control"
                                      onChange={handleChangeOriginalPriceId}
                                      placeholder="masukkan harga asli ID"
                                    />
                                  </InputGroup>
                                </Col>

                                <Col md={5}>
                                  <Label>
                                    Diskon ID{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <InputGroup>
                                    <Input
                                      value={valueDiscountId}
                                      type="text"
                                      className="form-control"
                                      onChange={handleChangeDiscountId}
                                      placeholder="masukkan diskon ID"
                                    />
                                    <InputGroupText>%</InputGroupText>
                                  </InputGroup>
                                </Col>

                                <Col md={2}>
                                  <Label>Total Harga ID</Label>
                                  <div>
                                    <h3>Rp{totalPriceId}</h3>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col className="mb-3 ">
                          <Label className="mb-2">Harga EN</Label>

                          <Card>
                            <CardBody>
                              <Row>
                                <Col md={5}>
                                  <Label>
                                    Harga Asli EN{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <InputGroup>
                                    <InputGroupText>$</InputGroupText>

                                    <Input
                                      value={valueOriginalPriceEn}
                                      type="text"
                                      className="form-control"
                                      onChange={handleChangeOriginalPriceEn}
                                      placeholder="masukkan harga asli"
                                    />
                                  </InputGroup>
                                </Col>

                                <Col md={5}>
                                  <Label>
                                    Diskon EN{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <InputGroup>
                                    <Input
                                      value={valueDiscountEn}
                                      type="text"
                                      className="form-control"
                                      onChange={handleChangeDiscountEn}
                                      placeholder="masukkan diskon"
                                    />
                                    <InputGroupText>%</InputGroupText>
                                  </InputGroup>
                                </Col>

                                <Col md={2}>
                                  <Label>Total Harga EN</Label>
                                  <div>
                                    <h3>US${totalPriceEn}</h3>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col md={2} className="mb-3 ">
                          <Label>
                            Durasi <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <Input
                              value={valueDuration}
                              type="text"
                              className="form-control"
                              onChange={handleChangeDuration}
                              placeholder="masukkan durasi"
                            />

                            <InputGroupText>Hari</InputGroupText>
                          </InputGroup>
                        </Col>

                        <div className="mb-3 ">
                          <Label>
                            Apakah Rencana Harga ini Di Rekomendasikan?{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Row>
                            <Col md="3">
                              <select
                                value={valueRecommended}
                                className="form-select"
                                onChange={onChangeRecommended}
                              >
                                <option value="">Silahkan Pilih</option>
                                <option value="0">Tidak</option>
                                <option value="1">Ya</option>
                              </select>
                            </Col>
                          </Row>
                        </div>

                        <div className="mb-3">
                          <Label>
                            Tipe Akses <span className="text-danger">*</span>
                          </Label>
                          <Row>
                            <Col md="3">
                              <select
                                value={valueAccessType}
                                className="form-select"
                                onChange={onChangeAccessType}
                              >
                                <option value="">
                                  Silahkan Pilih Tipe Akses
                                </option>
                                <option value="1">Bebas Iklan, Materi</option>
                                <option value="2">
                                  Bebas Iklan, Materi, Kamus
                                </option>
                              </select>
                            </Col>
                          </Row>
                        </div>

                        <div className="mb-3 ">
                          {listBenefit.map((d, i) => {
                            return (
                              <Row key={i} className="mb-3">
                                <Col md={11}>
                                  <Row>
                                    <Col md={6}>
                                      {i === 0 ? (
                                        <Label>
                                          Keuntungan ID{" "}
                                          <span className="text-danger">*</span>
                                        </Label>
                                      ) : null}

                                      <Input
                                        value={d.description_id}
                                        type="text"
                                        className="form-control"
                                        onChange={e =>
                                          handleChangeBenefitId(e, i)
                                        }
                                        placeholder="deskripsi keuntungan indonesia"
                                      />
                                    </Col>

                                    <Col md={6}>
                                      {i === 0 ? (
                                        <Label>
                                          Keuntungan EN{" "}
                                          <span className="text-danger">*</span>
                                        </Label>
                                      ) : null}

                                      <Input
                                        value={d.description_en}
                                        type="text"
                                        className="form-control"
                                        onChange={e =>
                                          handleChangeBenefitEn(e, i)
                                        }
                                        placeholder="deskripsi keuntungan inggris"
                                      />
                                    </Col>
                                  </Row>
                                </Col>

                                {i !== 0 ? (
                                  <Col md={1}>
                                    <Button
                                      color="danger"
                                      onClick={() =>
                                        handleRemoveColumnBenefit(i)
                                      }
                                    >
                                      <i className="bx bx-minus"></i>
                                    </Button>
                                  </Col>
                                ) : null}
                              </Row>
                            )
                          })}

                          <Col className="mt-2">
                            <Button
                              color="primary"
                              onClick={handleAddColumnBenefit}
                            >
                              Tambah Kolom
                            </Button>
                          </Col>
                        </div>
                      </Form>
                    </Col>

                    <Col className="mx-auto col-10">
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/pricing-plan"
                          className="btn btn-danger mb-2 me-2"
                        >
                          <i className="fas fa-arrow-left"></i> Kembali
                        </Link>
                        <Button
                          type="button"
                          color="primary"
                          className="mb-2"
                          onClick={onSubmit}
                          disabled={disabledButton}
                        >
                          <i className="fas fa-save"></i> Simpan
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CreateTopic.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(CreateTopic)
