import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  Label,
} from "reactstrap"

//redux
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

import { ApiSigninUser, ApiLoadUser } from "../../api/auth"

import { LOAD_USER } from "../../store/auth/actionTypes"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

const Login = props => {
  //meta title
  document.title = "Login | Economates"

  const dispatch = useDispatch()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, serError] = useState("")
  const [disabledButton, setDisabledButton] = useState(true)

  useEffect(() => {
    if (email !== "" && password !== "") {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
  }, [email, password])

  const onChangeEmail = useCallback(e => {
    const { value } = e.target
    serError("")
    setEmail(value)
  }, [])

  const onChangePassword = useCallback(e => {
    const { value } = e.target
    serError("")
    setPassword(value)
  }, [])

  const signIn = useCallback(
    type => {
      const payload = {
        email,
        password,
      }
      ApiSigninUser(payload).then(response => {
        if (response) {
          const { status, message, result } = response

          if (status === 200) {
            localStorage.setItem("token", result.token)
            ApiLoadUser().then(responseLoadUser => {
              if (responseLoadUser) {
                if (responseLoadUser.status === 200) {
                  dispatch({
                    type: LOAD_USER,
                    payload: {
                      isAuth: true,
                      user: responseLoadUser.result.data,
                    },
                  })
                }
              }
            })
          }

          if (status === 400) {
            serError(message)
          }
        }
      })
    },
    [email, password, dispatch]
  )

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4"></div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        signIn()
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          value={email}
                          placeholder="Enter email"
                          type="text"
                          onChange={onChangeEmail}
                        />
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={password}
                          type="password"
                          placeholder="Enter Password"
                          onChange={onChangePassword}
                        />
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          disabled={disabledButton}
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
