import React, { memo, useMemo } from "react"
import ReactApexChart from "react-apexcharts"

const getChartColorsArray = colors => {
  colors = JSON.parse(colors)
  return colors.map(function (value) {
    var newValue = value.replace(" ", "")
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(
        newValue
      )

      if (color.indexOf("#") !== -1) color = color.replace(" ", "")
      if (color) return color
      else return newValue
    } else {
      var val = value.split(",")
      if (val.length === 2) {
        var rgbaColor = getComputedStyle(
          document.documentElement
        ).getPropertyValue(val[0])
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")"
        return rgbaColor
      } else {
        return newValue
      }
    }
  })
}

const Apaexlinecolumn = ({ dataColors, dataChart }) => {
  const apaexlineColumnColors = getChartColorsArray(dataColors)
  const series = [
    {
      name: "Net Profit",
      data: [46, 57, 59, 54, 62, 58, 64],
    },
    // {
    //   name: "Revenue",
    //   data: [74, 83, 102, 97, 86, 106, 93, 114, 94],
    // },
    // {
    //   name: "Free Cash Flow",
    //   data: [37, 42, 38, 26, 47, 50, 54, 55, 43],
    // },
  ]

  //   const options = {
  //     chart: {
  //       stacked: true,

  //       toolbar: {
  //         show: false,
  //       },
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //         columnWidth: "45%",
  //         endingShape: "rounded",
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       show: true,
  //       width: 2,
  //       colors: ["transparent"],
  //     },
  //     colors: apaexlineColumnColors,
  //     xaxis: {
  //       categories: [
  //         "Feb",
  //         "Mar",
  //         "Apr",
  //         "May",
  //         "Jun",
  //         "Jul",
  //         "Aug",
  //         "Sep",
  //         "Oct",
  //       ],
  //     },
  //     // yaxis: {
  //     //   title: {
  //     //     text: "$ (thousands)",
  //     //   },
  //     // },
  //     grid: {
  //       borderColor: "#f1f1f1",
  //     },
  //     fill: {
  //       opacity: 1,
  //     },
  //     // tooltip: {
  //     //   y: {
  //     //     formatter: function (val) {
  //     //       return "$ " + val + " thousands"
  //     //     },
  //     //   },
  //     // },
  //   }

  const options = useMemo(() => {
    return {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      dataLabels: {
        enabled: false,
      },
    //   stroke: {
    //     show: true,
    //     //   width: 50,
    //     colors: ["transparent"],
    //   },
      colors: apaexlineColumnColors,
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          //   columnWidth: "100%",
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        categories: dataChart.categories,
      },
      // xaxis: {
      //   type: "datetime",
      //   categories: [
      //     "01/01/2011 GMT",
      //     "01/02/2011 GMT",
      //     "01/03/2011 GMT",
      //     "01/04/2011 GMT",
      //     "01/05/2011 GMT",
      //     "01/06/2011 GMT",
      //   ],
      // },
      // legend: {
      //   position: "right",
      //   offsetY: 40,
      // },
      fill: {
        opacity: 1,
      },
    }
  })

  //   var options = {
  //     series: [
  //       {
  //         name: "PRODUCT A",
  //         data: [44, 55, 41, 67, 22, 43],
  //       },
  //       {
  //         name: "PRODUCT B",
  //         data: [13, 23, 20, 8, 13, 27],
  //       },
  //       {
  //         name: "PRODUCT C",
  //         data: [11, 17, 15, 15, 21, 14],
  //       },
  //       {
  //         name: "PRODUCT D",
  //         data: [21, 7, 25, 13, 22, 8],
  //       },
  //     ],
  //     chart: {
  //       type: "bar",
  //       height: 350,
  //       stacked: true,
  //       toolbar: {
  //         show: true,
  //       },
  //       zoom: {
  //         enabled: true,
  //       },
  //     },
  //     responsive: [
  //       {
  //         breakpoint: 480,
  //         options: {
  //           legend: {
  //             position: "bottom",
  //             offsetX: -10,
  //             offsetY: 0,
  //           },
  //         },
  //       },
  //     ],
  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //         borderRadius: 10,
  //         dataLabels: {
  //           total: {
  //             enabled: true,
  //             style: {
  //               fontSize: "13px",
  //               fontWeight: 900,
  //             },
  //           },
  //         },
  //       },
  //     },
  //     xaxis: {
  //       categories: [
  //         "Feb",
  //         "Mar",
  //         "Apr",
  //         "May",
  //         "Jun",
  //         "Jul",
  //         "Aug",
  //         "Sep",
  //         "Oct",
  //       ],
  //     },
  //     legend: {
  //       position: "right",
  //       offsetY: 40,
  //     },
  //     fill: {
  //       opacity: 1,
  //     },
  //   }

  return (
    <ReactApexChart
      options={options}
      series={dataChart.series}
      type="bar"
      height={350}
    />
  )
}

export default memo(Apaexlinecolumn)
