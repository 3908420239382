import axios from "../lib/axios"

export const ApiGetStudent = async (search = "", page = "", limit = "") => {
  const response = axios.get(
    `/user/student?search=${search}&page=${page}&limit=${limit}`
  )
  return response
}

export const ApiAddStudent = async payload => {
  const response = axios.post(`/user/student`, payload)
  return response
}

export const ApiDetailStudent = async id => {
  const response = axios.get(`/user/student/${id}`)
  return response
}

export const ApiUpdateStudent = async (id, payload) => {
  const response = axios.put(`/user/student/${id}`, payload)
  return response
}

export const ApiDeleteStudent = async id => {
  const response = axios.delete(`/user/student/${id}`)
  return response
}

export const ApiGetActiveStudent = async (
  search = "",
  page = "",
  limit = ""
) => {
  const response = axios.get(
    `/user/student/active?search=${search}&page=${page}&limit=${limit}`
  )
  return response
}

export const ApiGetNoActiveStudent = async (
  search = "",
  page = "",
  limit = ""
) => {
  const response = axios.get(
    `/user/student/no-active?search=${search}&page=${page}&limit=${limit}`
  )
  return response
}

export const ApiGetTotalAllSummaryStudent = async () => {
  const response = axios.get(`/user/student/total-all-summary`)
  return response
}

export const ApiGetTotalAllLastOpenAppStudent = async (
  month = 2,
  year = 2024
) => {
  const response = axios.get(
    `/user/student/total-all-last-open-app?month=${month}&year=${year}`
  )
  return response
}

export const ApiGetSummaryRegisteredStudent = async () => {
  const response = axios.get(`/user/student/summary-registered`)
  return response
}
