import axios from "../lib/axios"

export const ApiGetPricingPlan = async activated => {
  let url
  if (activated) {
    url = `/pricing-plan?activated=${activated}`
  } else {
    url = `/pricing-plan`
  }
  const response = axios.get(url)
  return response
}

export const ApiUpdateActivePricingPlan = async (id, payload) => {
  const response = axios.patch(`/pricing-plan/update-active/${id}`, payload)
  return response
}

export const ApiAddPricingPlan = async payload => {
  const response = axios.post(`/pricing-plan`, payload)
  return response
}

export const ApiDetailPricingPlan = async id => {
  const response = axios.get(`/pricing-plan/${id}`)
  return response
}

export const ApiUpdatePricingPlan = async (id, payload) => {
  const response = axios.put(`/pricing-plan/${id}`, payload)
  return response
}

export const ApiDeletePricingPlan = async id => {
  const response = axios.delete(`/pricing-plan/${id}`)
  return response
}
