import dayjs from 'dayjs';
// import localizedFormat from 'dayjs/plugin/localizedFormat';
// import timezone from 'dayjs/plugin/timezone'

// dayjs.extend(localizedFormat)

// dayjs().format('L LT')
// dayjs.extend(timezone)
import 'dayjs/locale/id';

dayjs.locale('id');

export default dayjs;
