import PropTypes from "prop-types"
import React, { useCallback, useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Label,
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Table from "../../../components/Table"
import Pagination from "../../../components/Pagination"

import Modal from "../../../components/Modal"

import { ApiGetDictionary, ApiDeleteDictionary } from "../../../api/dictionary"

import dayjs from "../../../lib/dayjs"

import { limitPagination } from "../../../constants/data"

//i18n
import { withTranslation } from "react-i18next"

const Index = props => {
  //meta title
  document.title = "Rencana Harga | Economates"

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [dataDictionary, setDataDictionary] = useState([])
  const [totalDataDictionary, setTotalDataDictionary] = useState(0)
  const [dictionaryId, setDictionaryId] = useState("")
  const [modal, setModal] = useState({
    isOpen: false,
    title: "",
    description: "",
  })
  const [pagination, setPagination] = useState({
    totalPage: 0,
    currentPage: 1,
    nextPage: 0,
    prevPage: 0,
  })
  const [valueSearch, setValueSearch] = useState("")
  const [sortyBy, setSortBy] = useState("")
  const [sortyType, setSortType] = useState("")

  const handleClickUpdate = useCallback(
    id => {
      navigate(`/content/dictionary/update/${id}`)
    },
    [navigate]
  )

  const handleClickDelete = useCallback(id => {
    setModal(oldState => ({
      ...oldState,
      isOpen: true,
      title: "Confirmation",
      description: "Apakah anda yakin ingin menghapus data ini ?",
    }))
    setDictionaryId(id)
  }, [])

  const handleCloseModal = useCallback(() => {
    setModal(oldState => ({
      ...oldState,
      isOpen: false,
      title: "",
      description: "",
    }))
    setDictionaryId("")
  }, [])

  // const handleToogleActive = useCallback((id, value, index) => {
  //   const payload = {
  //     active: value === "0" ? "1" : "0",
  //   }
  //   ApiUpdateActivePricingPlan(id, payload).then(response => {
  //     if (response) {
  //       const { status } = response
  //       if (status === 200) {
  //         setDictionaryId(id)
  //         setPricingPlanActive(payload.active)
  //         setPricingPlanIndex(index)
  //       }
  //     }
  //   })
  // }, [])

  const handleGetData = useCallback(() => {
    ApiGetDictionary(
      pagination.currentPage,
      limitPagination,
      valueSearch,
      sortyBy,
      sortyType
    ).then(response => {
      if (response) {
        const { status, result } = response
        if (status === 200) {
          const dataArr = []
          for (let i = 0; i < result.data.length; i++) {
            dataArr.push({
              id: result.data[i].pricing_plan_id,
              name_id: result.data[i].name_id
                ? result.data[i].name_id
                : result.data[i].name,
              name_en: result.data[i].name_en ? result.data[i].name_en : "-",
              subNameId: result.data[i].sub_name_id
                ? result.data[i].sub_name_id
                : result.data[i].sub_name
                ? result.data[i].sub_name
                : "-",
              subNameEn: result.data[i].sub_name_en
                ? result.data[i].sub_name_en
                : "-",
              accessType: {
                type: "badge",
                color: result.data[i].access_type === "1" ? "warning" : "info",
                text: result.data[i].access_type === "1" ? "Premium" : "Free",
              },
              createdAt: dayjs(result.data[i].created_at).format(
                "DD MMMM YYYY HH:mm:ss"
              ),
              updatedAt:
                result.data[i].updated_at === null
                  ? "-"
                  : dayjs(result.data[i].updated_at).format(
                      "DD MMMM YYYY HH:mm:ss"
                    ),
              actions: (
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="card-drop" tag="i">
                    <i
                      style={{ cursor: "pointer" }}
                      className="mdi mdi-dots-horizontal font-size-18"
                    />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={() =>
                        handleClickUpdate(result.data[i].content_dictionary_id)
                      }
                    >
                      <i className="mdi mdi-pencil text-secondary me-1" />{" "}
                      <span className="text-secondary">Edit Data</span>
                    </DropdownItem>

                    <DropdownItem
                      onClick={() =>
                        handleClickDelete(result.data[i].content_dictionary_id)
                      }
                    >
                      <i className="mdi mdi-trash-can text-secondary me-1" />{" "}
                      <span className="text-secondary">Hapus Data</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ),
            })
          }

          if (!result.pagination.next && !result.pagination.prev) {
            setPagination(oldState => ({
              ...oldState,
              totalPage: result.totalPage,
            }))
          } else {
            if (result.pagination.next) {
              setPagination(oldState => ({
                ...oldState,
                nextPage: result.pagination.next.page,
              }))
            } else {
              setPagination(oldState => ({
                ...oldState,
                nextPage: 0,
              }))
            }

            if (result.pagination.prev) {
              setPagination(oldState => ({
                ...oldState,
                prevPage: result.pagination.prev.page,
              }))
            } else {
              setPagination(oldState => ({
                ...oldState,
                prevPage: 0,
              }))
            }

            setPagination(oldState => ({
              ...oldState,
              totalPage: result.totalPage,
            }))
          }

          setTotalDataDictionary(result.totalData)
          setDataDictionary(dataArr)
        }
      }

      setIsLoading(false)
    })
  }, [
    handleClickUpdate,
    handleClickDelete,
    pagination,
    valueSearch,
    sortyBy,
    sortyType,
  ])

  useEffect(() => {
    setIsLoading(true)
    let timeout = setTimeout(() => {
      handleGetData()
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [valueSearch, pagination.currentPage, sortyBy, sortyType])

  const handleSubmitDelete = useCallback(() => {
    ApiDeleteDictionary(dictionaryId).then(response => {
      if (response) {
        const { status, message } = response
        if (status === 200) {
          handleCloseModal()
          const state = [...dataDictionary]
          const indexData = state.map(d => d.id).indexOf(dictionaryId)
          state.splice(indexData, 1)
          const totalData = totalDataDictionary - 1
          const page = Math.ceil(totalData / limitPagination)
          setTotalDataDictionary(totalData)
          setPagination(oldState => ({
            ...oldState,
            currentPage: page,
          }))
          setDataDictionary(state)
          toastr.success(message, "Success")
        }
      }
    })
  }, [dictionaryId, handleCloseModal, totalDataDictionary, dataDictionary])

  const onChangeSearch = useCallback(e => {
    let { value } = e.target
    setValueSearch(value)
    setPagination({
      totalPage: 0,
      currentPage: 1,
      nextPage: 0,
      prevPage: 0,
    })
  }, [])

  const handleChangeSort = useCallback(
    (by, type) => {
      if (by === sortyBy && type === sortyType) {
        setSortBy("")
        setSortType("")
      } else {
        setSortBy(by)
        setSortType(type)
      }

      setPagination({
        totalPage: 0,
        currentPage: 1,
        nextPage: 0,
        prevPage: 0,
      })
    },
    [sortyBy, sortyType]
  )

  // useEffect(() => {
  //   if (pricingPlanActive) {
  //     const state = [...dataDictionary]
  //     state[pricingPlanIndex].active = (
  //       <Switch
  //         isChecked={pricingPlanActive === "1" ? true : false}
  //         onChange={() =>
  //           handleToogleActive(
  //             pricingPlanId,
  //             pricingPlanActive,
  //             pricingPlanIndex
  //           )
  //         }
  //       />
  //     )
  //     setDataDictionary(state)
  //     setPricingPlanIndex("")
  //     setDictionaryId("")
  //     setPricingPlanActive("")
  //   }
  // }, [dataDictionary, pricingPlanActive, pricingPlanId, pricingPlanIndex])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Kamus")}
            breadcrumbItem={props.t("Kamus")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-4">
                    <Col md="12" sm="12" className="d-flex justify-content-end">
                      <Link
                        to="/content/dictionary/create"
                        className="btn btn-primary"
                      >
                        <i className="fas fa-plus"></i> Tambah Kamus
                      </Link>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    {/* <Col md="3">
                      <select
                        value={valueScienceId}
                        className="form-select"
                        onChange={onChangeScience}
                      >
                        <option value="">Pilih Bidang Ilmu</option>
                        {dataScience.map((d, i) => (
                          <option value={d.id} key={i}>
                            {d.label}
                          </option>
                        ))}
                      </select>
                    </Col> */}

                    <Col md="3">
                      <Input
                        value={valueSearch}
                        onChange={onChangeSearch}
                        placeholder="cari kamus..."
                      />
                    </Col>

                    <Col md="3">
                      <UncontrolledDropdown>
                        <DropdownToggle type="button" className="btn btn-light">
                          Urutkan <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-md p-3">
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <Label className="m-0 font-size-16">Nama</Label>
                            </div>
                            <div className="d-flex">
                              <button
                                type="button"
                                className={`btn btn-sm me-2 ${
                                  sortyBy === "name" && sortyType === "asc"
                                    ? "btn-dark"
                                    : "btn-light"
                                }`}
                                onClick={() => handleChangeSort("name", "asc")}
                              >
                                <i className="bx bx-chevron-down font-size-22 align-middle"></i>{" "}
                              </button>
                              <button
                                type="button"
                                className={`btn btn-sm ${
                                  sortyBy === "name" && sortyType === "desc"
                                    ? "btn-dark"
                                    : "btn-light"
                                }`}
                                onClick={() => handleChangeSort("name", "desc")}
                              >
                                <i className="bx bx-chevron-up font-size-22 align-middle"></i>{" "}
                              </button>
                            </div>
                          </div>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12">
                      <Table
                        column={[
                          "Nama ID",
                          "Nama EN",
                          "Sub Nama ID",
                          "Sub Nama EN",
                          "Tipe Akses",
                          "Tanggal Dibuat",
                          "Tanggal Diedit",
                          "Aksi",
                        ]}
                        row={dataDictionary}
                        isLoading={isLoading}
                      />
                    </Col>
                  </Row>

                  {!isLoading ? (
                    <Row className="mt-3">
                      <Pagination
                        totalPage={pagination.totalPage}
                        currentPage={pagination.currentPage}
                        nextPage={pagination.nextPage}
                        prevPage={pagination.prevPage}
                        setPagination={setPagination}
                      />
                    </Row>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modal.isOpen}
        title={modal.title}
        description={modal.description}
        onClose={handleCloseModal}
        tetxButtonLeft="Cancel"
        tetxButtonRight="Ya, Hapus"
        onSubmit={handleSubmitDelete}
      />
    </React.Fragment>
  )
}

Index.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Index)
