import React, { useEffect } from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { LOAD_USER } from "./store/auth/actionTypes"
import "./i18n"
import { Provider } from "react-redux"

import store from "./store"

import { ApiLoadUser } from "./api/auth"

const Index = () => {
  useEffect(() => {
    ApiLoadUser().then(response => {
      if (response) {
        if (response.status === 200) {
          store.dispatch({
            type: LOAD_USER,
            payload: {
              isAuth: true,
              user: response.result.data,
            },
          })
        }
      }
    })
  }, [])

  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<Index />)

serviceWorker.unregister()
