import PropTypes from "prop-types"
import React, { useCallback, useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Table from "../../components/Table"
import Switch from "../../components/Switch"

import {
  ApiGetPricingPlan,
  ApiUpdateActivePricingPlan,
} from "../../api/pricingPlan"

import { ReplaceToRupiah } from "../../utils/replace"

import dayjs from "../../lib/dayjs"

//i18n
import { withTranslation } from "react-i18next"

const Index = props => {
  //meta title
  document.title = "Rencana Harga | Economates"

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [dataPricingPlan, setDataPricingPlan] = useState([])
  const [pricingPlanId, setPricingPlanId] = useState("")

  const [pricingPlanActive, setPricingPlanActive] = useState("")
  const [pricingPlanIndex, setPricingPlanIndex] = useState("")

  const handleClickUpdate = useCallback(
    id => {
      navigate(`/pricing-plan/update/${id}`)
    },
    [navigate]
  )

  const handleToogleActive = useCallback((id, value, index) => {
    const payload = {
      active: value === "0" ? "1" : "0",
    }
    ApiUpdateActivePricingPlan(id, payload).then(response => {
      if (response) {
        const { status } = response
        if (status === 200) {
          setPricingPlanId(id)
          setPricingPlanActive(payload.active)
          setPricingPlanIndex(index)
        }
      }
    })
  }, [])

  const handleGetData = useCallback(() => {
    ApiGetPricingPlan().then(response => {
      if (response) {
        const { status, result } = response
        if (status === 200) {
          const dataArr = []
          for (let i = 0; i < result.data.length; i++) {
            dataArr.push({
              id: result.data[i].pricing_plan_id,
              googlePlayProductId: result.data[i].google_play_product_id || "-",
              title_id: result.data[i].title_id
                ? result.data[i].title_id
                : result.data[i].title,
              title_en: result.data[i].title_en ? result.data[i].title_en : "-",
              duration: `${result.data[i].duration} Hari`,
              originalPriceId: `Rp${
                result.data[i].original_price_id
                  ? ReplaceToRupiah(result.data[i].original_price_id)
                  : ReplaceToRupiah(result.data[i].original_price)
              }`,
              discount_id:
                result.data[i].discount_id || result.data[i].discount_id === 0
                  ? `${result.data[i].discount_id}%`
                  : `${result.data[i].discount}%`,
              totalPriceId: result.data[i].total_price_id
                ? `Rp${ReplaceToRupiah(result.data[i].total_price_id)}`
                : `Rp${ReplaceToRupiah(result.data[i].total_price)}`,
              originalPriceEn: result.data[i].original_price_en
                ? `$${result.data[i].original_price_en}`
                : "-",
              discount_en:
                result.data[i].discount_en || result.data[i].discount_en === 0
                  ? `${result.data[i].discount_en}%`
                  : `-`,
              totalPriceEn: result.data[i].total_price_en
                ? `$${result.data[i].total_price_en}`
                : `-`,
              active: (
                <Switch
                  isChecked={result.data[i].is_active === "0" ? false : true}
                  onChange={() =>
                    handleToogleActive(
                      result.data[i].pricing_plan_id,
                      result.data[i].is_active,
                      i
                    )
                  }
                />
              ),
              createdAt: dayjs(result.data[i].created_at).format(
                "DD MMMM YYYY HH:mm:ss"
              ),
              updatedAt:
                result.data[i].updated_at === null
                  ? "-"
                  : dayjs(result.data[i].updated_at).format(
                      "DD MMMM YYYY HH:mm:ss"
                    ),
              actions: (
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="card-drop" tag="i">
                    <i
                      style={{ cursor: "pointer" }}
                      className="mdi mdi-dots-horizontal font-size-18"
                    />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={() =>
                        handleClickUpdate(result.data[i].pricing_plan_id)
                      }
                    >
                      <i className="mdi mdi-pencil text-secondary me-1" />{" "}
                      <span className="text-secondary">Edit Data</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ),
            })
          }
          setDataPricingPlan(dataArr)
        }
      }

      setIsLoading(false)
    })
  }, [handleClickUpdate])

  useEffect(() => {
    setIsLoading(true)
    handleGetData()
  }, [])

  useEffect(() => {
    if (pricingPlanActive) {
      const state = [...dataPricingPlan]
      state[pricingPlanIndex].active = (
        <Switch
          isChecked={pricingPlanActive === "1" ? true : false}
          onChange={() =>
            handleToogleActive(
              pricingPlanId,
              pricingPlanActive,
              pricingPlanIndex
            )
          }
        />
      )
      setDataPricingPlan(state)
      setPricingPlanIndex("")
      setPricingPlanId("")
      setPricingPlanActive("")
    }
  }, [dataPricingPlan, pricingPlanActive, pricingPlanId, pricingPlanIndex])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Rencana Harga")}
            breadcrumbItem={props.t("Rencana Harga")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-4">
                    <Col md="12" sm="12" className="d-flex justify-content-end">
                      <Link
                        to="/pricing-plan/create"
                        className="btn btn-primary"
                      >
                        <i className="fas fa-plus"></i> Tambah Rencana Harga
                      </Link>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12">
                      <Table
                        column={[
                          "Google Product Id",
                          "Nama ID",
                          "Nama EN",
                          "Durasi",
                          "Harga Asli ID",
                          "Diskon ID",
                          "Total Harga ID",
                          "Harga Asli EN",
                          "Diskon EN",
                          "Total Harga EN",
                          "Aktif",
                          "Tanggal Dibuat",
                          "Tanggal Diedit",
                          "Aksi",
                        ]}
                        row={dataPricingPlan}
                        isLoading={isLoading}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Index.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Index)
