import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import { LOAD_USER } from "../../../store/auth/actionTypes"

const ProfileMenu = props => {
  const dispatch = useDispatch()
  const { user } = useSelector(({ Auth }) => Auth)

  const [menu, setMenu] = useState(false)

  const handleLogout = useCallback(() => {
    dispatch({
      type: LOAD_USER,
      payload: {
        isAuth: false,
        user: {},
      },
    })
    localStorage.removeItem("token")
    props.router.navigate("/login")
  }, [dispatch, props])

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
    >
      <DropdownToggle
        className="btn header-item "
        id="page-header-user-dropdown"
        tag="button"
      >
        <img
          className="rounded-circle header-profile-user"
          src={user1}
          alt="Header Avatar"
        />
        <span className="d-none d-xl-inline-block ms-2 me-1">{user.name}</span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <Link to="#" className="dropdown-item">
          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
          <span onClick={handleLogout}>{props.t("Logout")}</span>
        </Link>
      </DropdownMenu>
    </Dropdown>
  )
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ProfileMenu))
