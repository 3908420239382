import axios from "../lib/axios"

export const ApiGetDictionary = async (
  page = "",
  limit = "",
  search = "",
  sortBy = "",
  sortType = ""
) => {
  const response = axios.get(
    `/content/dictionary?page=${page}&limit=${limit}&search=${search}&sortBy=${sortBy}&sortType=${sortType}`
  )
  return response
}

export const ApiAddDictionary = async payload => {
  const response = axios.post(`/content/dictionary`, payload)
  return response
}

export const ApiDetailDictionary = async id => {
  const response = axios.get(`/content/dictionary/${id}`)
  return response
}

export const ApiUpdateDictionary = async (id, payload) => {
  const response = axios.put(`/content/dictionary/${id}`, payload)
  return response
}

export const ApiDeleteDictionary = async id => {
  const response = axios.delete(`/content/dictionary/${id}`)
  return response
}
