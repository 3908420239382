export const ReplaceToRupiah = number => {
  if (!number) return ""
  const format = number.toString().split("").reverse().join("")
  const convert = format.match(/\d{1,3}/g)
  const hasil = convert.join(".").split("").reverse().join("")
  return hasil
}

export const ReplaceDot = value => {
  if (!value) return false
  return value.toString().replace(/\./g, "")
}

export const ReplaceToStartUpperCase = str => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const ReplaceTwoNumberZeroAfterPoint = value => {
  // Konversi input ke string
  const valueStr = value.toString()

  // Cek apakah angka memiliki bagian desimal
  if (valueStr.includes(".")) {
    // Split angka menjadi bagian integer dan desimal
    const [integerPart, decimalPart] = valueStr.split(".")

    // Jika bagian desimal adalah "00", hanya tampilkan bagian integer
    if (decimalPart === "00") {
      return addThousandSeparator(integerPart)
    }
  }

  // Jika tidak, biarkan angka apa adanya dengan menambahkan pemisah ribuan
  return addThousandSeparator(valueStr)
}

// Fungsi untuk menambahkan pemisah ribuan
function addThousandSeparator(numberStr) {
  // Gunakan regex untuk menambahkan titik sebagai pemisah ribuan
  return numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}
