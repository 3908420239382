import PropTypes from "prop-types"
import React, { useCallback, useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Table from "../../../components/Table"
import Switch from "../../../components/Switch"
import Pagination from "../../../components/Pagination"

import Modal from "../../../components/Modal"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { ApiGetScience } from "../../../api/science"
import { ApiGetTopic } from "../../../api/topic"
import {
  ApiGetMaterial,
  ApiDeleteMaterial,
  ApiActiveMaterial,
  ApiStatusLeatestMaterial,
} from "../../../api/material"

import { limitPagination } from "../../../constants/data"

import dayjs from "../../../lib/dayjs"

//i18n
import { withTranslation } from "react-i18next"

const Index = props => {
  //meta title
  document.title = "Materi | Economates"

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [dataMaterial, setDataMaterial] = useState([])
  const [materialId, setMaterialId] = useState("")
  const [totalDataMaterial, setTotalDataMaterial] = useState(0)
  const [modal, setModal] = useState({
    isOpen: false,
    title: "",
    description: "",
  })
  const [materialActive, setMaterialActive] = useState("")
  const [materialIndex, setMaterialIndex] = useState("")
  const [materialStatusLeatest, setMaterialStatusLeatest] = useState("")

  const [valueScienceId, setValueScienceId] = useState("")
  const [dataScience, setDataScience] = useState([])
  const [valueTopicId, setValueTopicId] = useState("")
  const [dataSourceTopic, setDataSourceTopic] = useState([])
  const [dataTopic, setDataTopic] = useState([])
  const [valueSearch, setValueSearch] = useState("")

  const [pagination, setPagination] = useState({
    totalPage: 0,
    currentPage: 1,
    nextPage: 0,
    prevPage: 0,
  })

  useEffect(() => {
    ApiGetScience().then(response => {
      if (response) {
        const { status, result } = response

        if (status === 200) {
          const dataArr = []
          for (let i = 0; i < result.data.length; i++) {
            dataArr.push({
              id: result.data[i].content_science_id,
              label: `${
                result.data[i].name_id
                  ? result.data[i].name_id
                  : result.data[i].name
              }`,
            })
          }
          setDataScience(dataArr)
        }
      }
    })
  }, [])

  useEffect(() => {
    ApiGetTopic().then(response => {
      if (response) {
        const { status, result } = response

        if (status === 200) {
          const dataArr = []
          for (let i = 0; i < result.data.length; i++) {
            dataArr.push({
              id: result.data[i].topicId,
              label: `${
                result.data[i].name_id
                  ? result.data[i].name_id
                  : result.data[i].name
              }`,
              scienceId: result.data[i].science.id,
            })
          }
          setDataSourceTopic(dataArr)
        }
      }
    })
  }, [])

  const onChangeScience = useCallback(
    e => {
      let { value } = e.target
      const filterDataTopic = dataSourceTopic.filter(
        d => d.scienceId === Number(value)
      )
      setValueScienceId(value)
      setValueTopicId("")
      setDataTopic(filterDataTopic)
      setPagination({
        totalPage: 0,
        currentPage: 1,
        nextPage: 0,
        prevPage: 0,
      })
    },
    [dataSourceTopic]
  )

  const onChangeTopic = useCallback(e => {
    let { value } = e.target
    setValueTopicId(value)
    setPagination({
      totalPage: 0,
      currentPage: 1,
      nextPage: 0,
      prevPage: 0,
    })
  }, [])

  const onChangeSearch = useCallback(e => {
    let { value } = e.target
    setValueSearch(value)
    setPagination({
      totalPage: 0,
      currentPage: 1,
      nextPage: 0,
      prevPage: 0,
    })
  }, [])

  const handleClickUpdate = useCallback(
    id => {
      navigate(`/content/material/update/${id}`)
    },
    [navigate]
  )

  const handleClickDelete = useCallback(id => {
    setModal(oldState => ({
      ...oldState,
      isOpen: true,
      title: "Confirmation",
      description: "Apakah anda yakin ingin menghapus data ini ?",
    }))
    setMaterialId(id)
  }, [])

  const handleToogleActive = useCallback((id, value, index) => {
    const payload = {
      isActive: value === "0" ? "1" : "0",
    }
    ApiActiveMaterial(id, payload).then(response => {
      if (response) {
        const { status } = response
        if (status === 200) {
          setMaterialId(id)
          setMaterialActive(payload.isActive)
          setMaterialIndex(index)
        }
      }
    })
  }, [])

  const handleToogleStatusLeatest = useCallback((id, value, index) => {
    const payload = {
      status: value === "0" ? "1" : "0",
    }
    ApiStatusLeatestMaterial(id, payload).then(response => {
      if (response) {
        const { status } = response
        if (status === 200) {
          setMaterialId(id)
          setMaterialStatusLeatest(payload.status)
          setMaterialIndex(index)
        }
      }
    })
  }, [])

  const handleGetData = useCallback(() => {
    ApiGetMaterial(
      valueScienceId,
      valueTopicId,
      valueSearch,
      pagination.currentPage,
      limitPagination
    ).then(response => {
      if (response) {
        const { status, result } = response
        if (status === 200) {
          const dataArr = []

          for (let i = 0; i < result.data.length; i++) {
            dataArr.push({
              id: result.data[i].materialId,
              uuid: result.data[i].uuid ? result.data[i].uuid : "-",
              ilmu_id: result.data[i].science.name_id
                ? result.data[i].science.name_id
                : result.data[i].science.name,
              ilmu_en: result.data[i].science.name_en
                ? result.data[i].science.name_en
                : "-",
              topic_id: result.data[i].topic.name_id
                ? result.data[i].topic.name_id
                : result.data[i].topic.name,
              topic_en: result.data[i].topic.name_en
                ? result.data[i].topic.name_en
                : "-",
              name_id: result.data[i].name_id
                ? result.data[i].name_id
                : result.data[i].name,
              name_en: result.data[i].name_en ? result.data[i].name_en : "-",
              statusLeatest: (
                <Switch
                  isChecked={result.data[i].is_new === "0" ? false : true}
                  onChange={() =>
                    handleToogleStatusLeatest(
                      result.data[i].materialId,
                      result.data[i].is_new,
                      i
                    )
                  }
                />
              ),
              active: (
                <Switch
                  isChecked={result.data[i].is_active === "0" ? false : true}
                  onChange={() =>
                    handleToogleActive(
                      result.data[i].materialId,
                      result.data[i].is_active,
                      i
                    )
                  }
                />
              ),
              accessType: {
                type: "badge",
                color: result.data[i].access_type === "1" ? "warning" : "info",
                text: result.data[i].access_type === "1" ? "Premium" : "Free",
              },
              createdAt: dayjs(result.data[i].created_at).format(
                "DD MMMM YYYY HH:mm:ss"
              ),
              updatedAt:
                result.data[i].updated_at === null
                  ? "-"
                  : dayjs(result.data[i].updated_at).format(
                      "DD MMMM YYYY HH:mm:ss"
                    ),
              createdBy: result.data[i].created_by.name,
              updatedBy:
                result.data[i].updated_by.name === null
                  ? "-"
                  : result.data[i].updated_by.name,
              actions: (
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="card-drop" tag="i">
                    <i
                      style={{ cursor: "pointer" }}
                      className="mdi mdi-dots-horizontal font-size-18"
                    />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={() =>
                        handleClickUpdate(result.data[i].materialId)
                      }
                    >
                      <i className="mdi mdi-pencil text-secondary me-1" />{" "}
                      <span className="text-secondary">Edit Data</span>
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        handleClickDelete(result.data[i].materialId)
                      }
                    >
                      <i className="mdi mdi-trash-can text-secondary me-1" />{" "}
                      <span className="text-secondary">Hapus Data</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ),
            })
          }

          if (!result.pagination.next && !result.pagination.prev) {
            setPagination(oldState => ({
              ...oldState,
              totalPage: result.totalPage,
            }))
          } else {
            if (result.pagination.next) {
              setPagination(oldState => ({
                ...oldState,
                nextPage: result.pagination.next.page,
              }))
            } else {
              setPagination(oldState => ({
                ...oldState,
                nextPage: 0,
              }))
            }

            if (result.pagination.prev) {
              setPagination(oldState => ({
                ...oldState,
                prevPage: result.pagination.prev.page,
              }))
            } else {
              setPagination(oldState => ({
                ...oldState,
                prevPage: 0,
              }))
            }

            setPagination(oldState => ({
              ...oldState,
              totalPage: result.totalPage,
            }))
          }
          setTotalDataMaterial(result.totalData)
          setDataMaterial(dataArr)
        }
      }

      setIsLoading(false)
    })
  }, [
    handleClickUpdate,
    handleClickDelete,
    valueScienceId,
    valueTopicId,
    valueSearch,
    pagination,
    handleToogleStatusLeatest,
    handleToogleActive,
  ])

  useEffect(() => {
    setIsLoading(true)
    let timeout = setTimeout(() => {
      handleGetData()
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [valueScienceId, valueTopicId, valueSearch, pagination.currentPage])

  useEffect(() => {
    if (materialActive) {
      const state = [...dataMaterial]
      state[materialIndex].active = (
        <Switch
          isChecked={materialActive === "1" ? true : false}
          onChange={() =>
            handleToogleActive(materialId, materialActive, materialIndex)
          }
        />
      )
      setDataMaterial(state)
      setMaterialIndex("")
      setMaterialId("")
      setMaterialActive("")
    }
  }, [
    dataMaterial,
    materialActive,
    materialId,
    materialIndex,
    handleToogleActive,
  ])

  useEffect(() => {
    if (materialStatusLeatest) {
      const state = [...dataMaterial]
      state[materialIndex].statusLeatest = (
        <Switch
          isChecked={materialStatusLeatest === "1" ? true : false}
          onChange={() =>
            handleToogleStatusLeatest(
              materialId,
              materialStatusLeatest,
              materialIndex
            )
          }
        />
      )
      setDataMaterial(state)
      setMaterialIndex("")
      setMaterialId("")
      setMaterialStatusLeatest("")
    }
  }, [
    dataMaterial,
    materialStatusLeatest,
    materialId,
    materialIndex,
    handleToogleStatusLeatest,
  ])

  const handleCloseModal = useCallback(() => {
    setModal(oldState => ({
      ...oldState,
      isOpen: false,
      title: "",
      description: "",
    }))
    setMaterialId("")
  }, [])

  const handleSubmitDelete = useCallback(() => {
    ApiDeleteMaterial(materialId).then(response => {
      if (response) {
        const { status, message } = response

        if (status === 200) {
          handleCloseModal()
          const state = [...dataMaterial]
          const indexData = state.map(d => d.id).indexOf(materialId)
          state.splice(indexData, 1)
          const totalData = totalDataMaterial - 1
          const page = Math.ceil(totalData / limitPagination)
          setTotalDataMaterial(totalData)
          setPagination(oldState => ({
            ...oldState,
            currentPage: page,
          }))
          setDataMaterial(state)
          toastr.success(message, "Success")
        }
      }
    })
  }, [materialId, handleCloseModal, dataMaterial, totalDataMaterial])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Materi")}
            breadcrumbItem={props.t("Materi")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-4">
                    <Col md="12" sm="12" className="d-flex justify-content-end">
                      <Link
                        to="/content/material/create"
                        className="btn btn-primary"
                      >
                        <i className="fas fa-plus"></i> Tambah Materi
                      </Link>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col md="3">
                      <select
                        value={valueScienceId}
                        className="form-select"
                        onChange={onChangeScience}
                      >
                        <option value="">Pilih Bidang Ilmu</option>
                        {dataScience.map((d, i) => (
                          <option value={d.id} key={i}>
                            {d.label}
                          </option>
                        ))}
                      </select>
                    </Col>

                    <Col md="3">
                      <select
                        value={valueTopicId}
                        className="form-select"
                        onChange={onChangeTopic}
                      >
                        <option value="">Pilih Topik</option>
                        {dataTopic.map((d, i) => (
                          <option value={d.id} key={i}>
                            {d.label}
                          </option>
                        ))}
                      </select>
                    </Col>

                    <Col md="3">
                      <Input
                        value={valueSearch}
                        onChange={onChangeSearch}
                        placeholder="cari materi..."
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12">
                      <Table
                        column={[
                          "UUID",
                          "Nama Ilmu ID",
                          "Nama Ilmu EN",
                          "Nama Topik ID",
                          "Nama Topik EN",
                          "Nama Materi ID",
                          "Nama Materi EN",
                          "Status Terbaru",
                          "Aktif",
                          "Tipe Akses",
                          "Tanggal Dibuat",
                          "Tanggal Diedit",
                          "Dibuat Oleh",
                          "Diedit Oleh",
                          "Aksi",
                        ]}
                        row={dataMaterial}
                        isLoading={isLoading}
                      />
                    </Col>
                  </Row>

                  {!isLoading ? (
                    <Row className="mt-3">
                      <Pagination
                        totalPage={pagination.totalPage}
                        currentPage={pagination.currentPage}
                        nextPage={pagination.nextPage}
                        prevPage={pagination.prevPage}
                        setPagination={setPagination}
                      />
                    </Row>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modal.isOpen}
        title={modal.title}
        description={modal.description}
        onClose={handleCloseModal}
        tetxButtonLeft="Cancel"
        tetxButtonRight="Ya, Hapus"
        onSubmit={handleSubmitDelete}
      />
    </React.Fragment>
  )
}

Index.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Index)
