import axios from "axios"
import { baseUrlApi } from "../config/env"
import store from "../store"
import { LOAD_USER } from "../store/auth/actionTypes"
import { CHANGE_ERROR_COMMON } from "../store/common/actionTypes"

const axiosInstance = axios.create({
  baseURL: baseUrlApi,
})

axiosInstance.interceptors.request.use(async config => {
  config.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    Accept: "application/json",
  }
  return config
})

axiosInstance.interceptors.response.use(
  async response => {
    if (response.data.status === 401) {
      store.dispatch({
        type: LOAD_USER,
        payload: {
          isAuth: false,
          user: {},
        },
      })
      localStorage.removeItem("token")
    } else {
      return response.data
    }
  },
  async error => {
    console.log("error", error)
  }
)

export default axiosInstance
