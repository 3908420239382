import React from "react"
import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import withRouter from "../components/Common/withRouter"

const Authmiddleware = props => {
  const { isAuth } = useSelector(({ Auth }) => Auth)
  const pathname = props.router.location.pathname
  if (isAuth === false && pathname !== "/login") {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  }

  if (isAuth === true && props.router.location.pathname === "/login") {
    return (
      <Navigate
        to={{ pathname: "/dashboard", state: { from: props.location } }}
      />
    )
  }

  return <React.Fragment>{props.children}</React.Fragment>
}

export default withRouter(Authmiddleware)
