import PropTypes from "prop-types"
import React, { useState, useCallback, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap"

import { Link } from "react-router-dom"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { ApiAddScience } from "../../../api/science"

const CreateTopic = props => {
  //meta title
  document.title = "Tambah Ilmu | Economates"

  const [valueNameId, setValueNameId] = useState("")
  const [valueNameEn, setValueNameEn] = useState("")
  const [disabledButton, setDisabledButton] = useState(true)

  useEffect(() => {
    if (valueNameId === "" || valueNameEn === "") {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
  }, [valueNameId, valueNameEn])

  const handleChangeNameId = useCallback(e => {
    const { value } = e.target
    setValueNameId(value)
  }, [])

  const handleChangeNameEn = useCallback(e => {
    const { value } = e.target
    setValueNameEn(value)
  }, [])

  const onSubmit = useCallback(() => {
    const payload = {
      name_id: valueNameId,
      name_en: valueNameEn,
    }
    ApiAddScience(payload).then(response => {
      if (response) {
        const { status, message } = response

        if (status == 201) {
          setValueNameId("")
          setValueNameEn("")
          toastr.success(message, "Success")
        }
      }
    })
  }, [valueNameId, valueNameEn])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Tambah")}
            breadcrumbItem={props.t("Ilmu")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="mx-auto col-10">
                      <Form>
                        <Row>
                          <Col md="6" className="mb-3">
                            <Label>
                              Nama Ilmu ID{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={valueNameId}
                              type="text"
                              className="form-control"
                              onChange={handleChangeNameId}
                              placeholder="masukkan nama ilmu indonesia"
                            />
                          </Col>

                          <Col md="6" className="mb-3">
                            <Label>
                              Nama Ilmu EN{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={valueNameEn}
                              type="text"
                              className="form-control"
                              onChange={handleChangeNameEn}
                              placeholder="masukkan nama ilmu inggris"
                            />
                          </Col>
                        </Row>
                      </Form>
                    </Col>

                    <Col className="mx-auto col-10">
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/content/science"
                          className="btn btn-danger mb-2 me-2"
                        >
                          <i className="fas fa-arrow-left"></i> Kembali
                        </Link>
                        <Button
                          type="button"
                          color="primary"
                          className="mb-2"
                          onClick={onSubmit}
                          disabled={disabledButton}
                        >
                          <i className="fas fa-save"></i> Simpan
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CreateTopic.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(CreateTopic)
