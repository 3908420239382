const currencyCode = {
  US: "USD", // United States
  ID: "IDR", // Indonesia
  GB: "GBP", // United Kingdom
  JP: "JPY", // Japan
  AU: "AUD", // Australia
  CA: "CAD", // Canada
  CH: "CHF", // Switzerland
  CN: "CNY", // China
  SE: "SEK", // Sweden
  NZ: "NZD", // New Zealand
  MX: "MXN", // Mexico
  SG: "SGD", // Singapore
  HK: "HKD", // Hong Kong
  NO: "NOK", // Norway
  KR: "KRW", // South Korea
  TR: "TRY", // Turkey
  RU: "RUB", // Russia
  IN: "INR", // India
  BR: "BRL", // Brazil
  ZA: "ZAR", // South Africa
  PH: "PHP", // Philippines
  MY: "MYR", // Malaysia
  TH: "THB", // Thailand
  VN: "VND", // Vietnam
  PL: "PLN", // Poland
  IL: "ILS", // Israel
  DK: "DKK", // Denmark
  HU: "HUF", // Hungary
  CZ: "CZK", // Czech Republic
  AE: "AED", // United Arab Emirates
  AR: "ARS", // Argentina
  CL: "CLP", // Chile
  CO: "COP", // Colombia
  EG: "EGP", // Egypt
  PK: "PKR", // Pakistan
  BD: "BDT", // Bangladesh
  LK: "LKR", // Sri Lanka
  KW: "KWD", // Kuwait
  OM: "OMR", // Oman
  QA: "QAR", // Qatar
  SA: "SAR", // Saudi Arabia
  KE: "KES", // Kenya
  GH: "GHS", // Ghana
  NG: "NGN", // Nigeria
  TZ: "TZS", // Tanzania
  UG: "UGX", // Uganda
  SN: "XOF", // West African CFA Franc (Senegal)
  MA: "MAD", // Morocco
  TN: "TND", // Tunisia
  DZ: "DZD", // Algeria
  RO: "RON", // Romania
  BG: "BGN", // Bulgaria
  HR: "HRK", // Croatia
  IS: "ISK", // Iceland
  LT: "EUR", // Lithuania (Euro)
  LV: "EUR", // Latvia (Euro)
  EE: "EUR", // Estonia (Euro)
  PT: "EUR", // Portugal (Euro)
  GR: "EUR", // Greece (Euro)
  FI: "EUR", // Finland (Euro)
  MT: "EUR", // Malta (Euro)
  CY: "EUR", // Cyprus (Euro)
  SK: "EUR", // Slovakia (Euro)
  SI: "EUR", // Slovenia (Euro)
  LU: "EUR", // Luxembourg (Euro)
  BE: "EUR", // Belgium (Euro)
  NL: "EUR", // Netherlands (Euro)
  IE: "EUR", // Ireland (Euro)
  AT: "EUR", // Austria (Euro)
  FR: "EUR", // France (Euro)
  ES: "EUR", // Spain (Euro)
  IT: "EUR", // Italy (Euro)
  DE: "EUR", // Germany (Euro)
  BM: "BMD", // Bermuda
  KY: "KYD", // Cayman Islands
  JM: "JMD", // Jamaica
  TT: "TTD", // Trinidad and Tobago
  BS: "BSD", // Bahamas
  BB: "BBD", // Barbados
  DO: "DOP", // Dominican Republic
  CR: "CRC", // Costa Rica
  PA: "PAB", // Panama
  PY: "PYG", // Paraguay
  UY: "UYU", // Uruguay
  BO: "BOB", // Bolivia
  EC: "USD", // Ecuador (uses US Dollar)
  SV: "USD", // El Salvador (uses US Dollar)
  GT: "GTQ", // Guatemala
  HN: "HNL", // Honduras
  NI: "NIO", // Nicaragua
  VE: "VES", // Venezuela
  PE: "PEN", // Peru
  IR: "IRR", // Iran
  IQ: "IQD", // Iraq
  JO: "JOD", // Jordan
  LB: "LBP", // Lebanon
  SY: "SYP", // Syria
  YE: "YER", // Yemen
  MM: "MMK", // Myanmar
  KH: "KHR", // Cambodia
  LA: "LAK", // Laos
  MN: "MNT", // Mongolia
  NP: "NPR", // Nepal
  BT: "BTN", // Bhutan
  KZ: "KZT", // Kazakhstan
  UZ: "UZS", // Uzbekistan
  TJ: "TJS", // Tajikistan
  KG: "KGS", // Kyrgyzstan
  TM: "TMT", // Turkmenistan
  GE: "GEL", // Georgia
  AM: "AMD", // Armenia
  AZ: "AZN", // Azerbaijan
  BY: "BYN", // Belarus
  MD: "MDL", // Moldova
  UA: "UAH", // Ukraine
  RS: "RSD", // Serbia
  MK: "MKD", // North Macedonia
  AL: "ALL", // Albania
  BA: "BAM", // Bosnia and Herzegovina
  ME: "EUR", // Montenegro (Euro)
  XK: "EUR", // Kosovo (Euro)
  MV: "MVR", // Maldives
  AF: "AFN", // Afghanistan
  BH: "BHD", // Bahrain
  CY: "EUR", // Cyprus (Euro)
  JO: "JOD", // Jordan
}

export default currencyCode;
